import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { ClientService } from '../../../api/ClientService';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function AdjustBalancesModal({ adamClient, accessToken, balanceUpdate, setBalanceUpdate, onClose }) {

    const [curEditingBalanceOffset, setCurEditingBalanceOffset] = useState(null)

    const [asset, setAsset] = useState('USD')
    const [offset, setOffset] = useState('')
    const [note, setNote] = useState('')

    const [adjustmentDate, setAdjustmentDate] = useState(new Date())

    const [show, setShow] = useState(false)

    const [balanceOffsets, setBalanceOffsets] = useState([])
    const [assetList, setAssetList] = useState([])

    const handleShow = () => setShow(true)

    const clientService = new ClientService(accessToken)

    /**
     * Executes a side effect when the component renders or when the dependencies change.
     * If the `show` variable is true, it calls the `getClientBalanceOffsets` method of the `clientService`
     * object with the `adamClient.clientCopperId` as an argument. It then sets the retrieved data as the
     * value of the `balanceOffsets` state variable.
     */
    useEffect(() => {
        if(show)
            clientService.getClientBalanceOffsets(adamClient.clientCopperId)
                .then(data => setBalanceOffsets(data))
    }, [adamClient, show])

    /**
     * useEffect hook that fetches the list of trading pairs assets from the API and updates the state
     * with the sorted data when the 'show' prop changes.
     */
    useEffect(() => {
        if(show) {
            fetch(`${clientService.API_URL}/tradingpairs/assets`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(res => res.json())
            .then(data => setAssetList(data.sort((asset1, asset2) => asset1.localeCompare(asset2))))
        }
        else {
            setCurEditingBalanceOffset(null)
        }

    }, [show])

    /**
     * useEffect hook that updates the state variables when the 'show' or 'curEditingBalanceOffset' dependencies change.
     * If 'show' is true, it sets the 'asset', 'offset', 'note', and 'adjustmentDate' state variables to the corresponding values from 'curEditingBalanceOffset'.
     * If 'curEditingBalanceOffset' is null or undefined, it sets the 'asset', 'offset', 'note', and 'adjustmentDate' state variables to their default values.
     */
    useEffect(() => {
        if(show) {
            setAsset(curEditingBalanceOffset?.asset ?? '')
            setOffset(curEditingBalanceOffset?.offset ?? '')
            setNote(curEditingBalanceOffset?.note ?? '')

            setAdjustmentDate(curEditingBalanceOffset?.adjustmentDate ?? new Date())

        }
    }, [show, curEditingBalanceOffset])


    useEffect(() => {
        setShow(true);  // Open the modal when the component mounts
     }, [adamClient]);
    /**
     * Handles the submission of adjusting the balance offset.
     * If the offset amount is not a valid number, it defaults to 0.0.
     * Constructs the balance offset data object with the provided values.
     * Calls the appropriate client service method to create or update the balance offset.
     * Updates the balance update state to trigger a re-render.
     */
    const onAdjustBalanceSubmit = () => {
        const parsedOffsetAmount = parseFloat(offset)
        const offsetAmount = isNaN(parsedOffsetAmount) ? 0.0 : parsedOffsetAmount

        const balanceOffsetData = {
            id: curEditingBalanceOffset?.id,
            asset,
            offset: offsetAmount,
            note,
            adjustmentDate
        }

        const reqCallback = () => {
            setShow(false)
            setBalanceUpdate(prevUpdate => prevUpdate + 1)
        }

        if(!curEditingBalanceOffset)
            clientService.createClientBalanceOffset(adamClient.clientCopperId, balanceOffsetData).then(reqCallback)

        else
            clientService.updateClientBalanceOffset(adamClient.clientCopperId, balanceOffsetData).then(reqCallback)
    }

    const onEditBalanceOffset = balanceOffset => {
        setCurEditingBalanceOffset(balanceOffset)
    }

    const handleClose = () => {
        setShow(false);
        if (onClose) {
            onClose(); // call the callback function
        }
    };

    /**
     * Deletes a balance offset for a client.
     */
    const onDeleteBalanceOffset = balanceOffset => {
        if(window.confirm('Are you sure you want to delete this balance adjustment?')) {
            clientService.deleteClientBalanceOffset(balanceOffset.id).then(() => {
                // setShow(false)
                setBalanceUpdate(prevUpdate => prevUpdate + 1)
            })
        }
    }

    return (
        <>
            {/* <Button size="small" variant="contained" color='success' onClick={handleShow}>
            Adjust Balances
            </Button> */}
            <Dialog open={show} onClose={handleClose}>
                <DialogContent>
                    <form onSubmit={e => onAdjustBalanceSubmit()}>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label style={{fontWeight: 'bold'}}>Client Name:</label>
                                <p>{adamClient.clientPrimaryName}</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="asset" style={{fontWeight: 'bold'}}>Asset</label>
                                <select className="form-control" name="asset" id="asset" value={asset} onChange={e => setAsset(e.target.value)}>
                                    <option value="">Select...</option>
                                    {
                                        assetList.map(asset => (
                                            <option key={asset} value={asset}>{asset}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6" style={{fontWeight: 'bold'}}>
                                <label htmlFor="offset">Offset Amount</label>
                                <input type="number" step={0.00000001} className="form-control" required name="offset" id="offset" value={offset} onChange={e => setOffset(e.target.value)} />
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6" style={{fontWeight: 'bold'}}>
                                <label htmlFor="note">Note</label>
                                <input type="text" className="form-control" name="note" id="note" value={note} onChange={e => setNote(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group col-md-6" style={{fontWeight: 'bold'}}>
                            <label>Adjustment Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    value={dayjs(adjustmentDate)}
                                    onChange={newDate => setAdjustmentDate(newDate)}
                                    variant='standard'
                                    slotProps={{ textField: { size: 'small' } }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="btncenter">
                            <input type="submit" className="btn btn-danger" />
                        </div>
                    </form>
                    <div>
                        {balanceOffsets.map(balanceOffset => (
                            <div key={balanceOffset.id} style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: 1 }}>
                                    <p>
                                        {
                                            curEditingBalanceOffset?.id === balanceOffset.id ? 
                                            <>
                                                <b>EDITING</b>
                                                <br />
                                            </>
                                            :
                                            <></>
                                        }
                                        Asset: {balanceOffset.asset}
                                        <br />
                                        Amount: {balanceOffset.offset}
                                        <br />
                                        Note: {balanceOffset.note}
                                        <br />
                                        Date: {moment(balanceOffset.adjustmentDate).format('LLL')}
                                    </p>
                                </div>
                                <div>
                                    <button onClick={() => onEditBalanceOffset(balanceOffset)}>Edit</button>
                                    <button onClick={() => onDeleteBalanceOffset(balanceOffset)}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AdjustBalancesModal
