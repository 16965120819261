import { Autocomplete, TextField, Button, Stack, CircularProgress } from '@mui/material';
import { useState } from 'react'

export default function ClientAutocompleteSelector({ clients, onChange, value, onClear }) {
    const [ buttonLoading, setButtonLoading ] = useState(false)
    
    const clientOptions = clients.map(client => ({
        id: client.clientCopperId,
        label: client.clientPrimaryName
    }))

    const handleClearClick = async () => {
        setButtonLoading(true)
        await onClear()
        setButtonLoading(false)
    }

    return (
        <Stack direction='row'>
            <Autocomplete 
                disablePortal
                disableClearable // clearing handled by "Clear" button
                id="combo-box-demo"
                options={clientOptions}
                sx={{width: '300px'}}
                size='small'
                isOptionEqualToValue={(option, value) => option.label === value}
                value={value}
                onChange={onChange}
                renderInput={params => <TextField {...params} placeholder="Client Name"/>}
            />
            <Button color='error' disabled={buttonLoading || value === null} onClick={handleClearClick}>{buttonLoading ? <CircularProgress size={20} /> : 'Clear'}</Button>
        </Stack>
    )
}