import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import SimpleTablePagination from '../../../components/SimpleTablePagination';

function RawTalosCustomerData({rawData, loading, paginationProps}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [openOrderDetail, setOpenOrderDetail] = useState(false);
    const [txData, setTxData] = useState();
    const [quoteData, setQuoteData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }

    const handleOpenOrderDetailDialog = (row, index) => {
        setQuoteData(row)
        setCurTxIndex(index)
        setOpenOrderDetail(true)
    }
    const handleCloseOrderDetailDialog = () => {
        setOpenOrderDetail(false)
        setCurTxIndex(-1)
    }
    const RawCFDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                {/* <TableCell>{rawDataRow.id}</TableCell> */}
                <TableCell align='left'>{moment(rawDataRow.transact_time).utcOffset(-4 * 60).format('YYYY-MM-DD hh:mm A')}</TableCell>
                <TableCell align='left'>{rawDataRow.counter_party}</TableCell>
                <TableCell align='left'>{rawDataRow.side}</TableCell>
                <TableCell align='left'>{rawDataRow.currency}</TableCell>
                <TableCell align='left'>{rawDataRow.order_qty}</TableCell>
                <TableCell align='left'>{rawDataRow.data.Price}</TableCell>
                <TableCell align='left'>{rawDataRow.cum_amt} {rawDataRow.amount_currency}</TableCell>
                <TableCell align='left'>{rawDataRow.customer_quote.hedgeTrades && rawDataRow.customer_quote.hedgeTrades.Price}</TableCell>
                <TableCell align='left'>{rawDataRow.customer_quote.dealerTrades && rawDataRow.customer_quote.dealerTrades.Price}</TableCell>
                <TableCell align='left'>{rawDataRow.order_type}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
                {/* <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenOrderDetailDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell> */}
            </TableRow>
        )
    }
    const talosTable = rawData.map((rawDataRow,index) => RawCFDataRow(rawDataRow,index))

    if (rawData.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Id</TableCell> */}
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Customer</TableCell> 
                                <TableCell align="left">Side</TableCell>
                                <TableCell align="left">Currency</TableCell>
                                <TableCell align="left">Quantity</TableCell>
                                <TableCell align="left">Price</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Hedge Price</TableCell>    
                                <TableCell align="left">Dealer Price</TableCell>          
                                <TableCell align="left">Order Type</TableCell>  
                                <TableCell align="left">Tx detail</TableCell>
                                {/* <TableCell align="left">Customer Quote</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {talosTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Talos Order Details"}
            />
            {/* <TransactionInfoModal 
                openDialog={openOrderDetail} 
                closeDialog={handleCloseOrderDetailDialog}
                data={txData}
                title={"Talos Customer Quote Details"}
            /> */}
        </div>
    );
}

export default RawTalosCustomerData;