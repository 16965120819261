import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import { CircularProgress } from '@mui/material';
import ClientAutocompleteSelector from '../../../components/clientAutocompleteSelector';
import SimpleTablePagination from '../../../components/SimpleTablePagination'
function RawPrimetrustData({ rawPrimetrust, loading, clients, setPrimeTrustClient, updateRawPrimeTrustEntry, paginationProps }) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTransferDetailInfo, setOpenTransferDetailInfo] = useState(false);
    const [openTransferMethodInfo, setOpenTransferMethodInfo] = useState(false);
    const [transferData, setTransferData] = useState();
    const [transferMethodData, setTransferMethodData] = useState();
    const handleOpenTransferDetailDialog = (row, index) => {
        setTransferData(row)
        setCurTxIndex(index)
        setOpenTransferDetailInfo(true)
    }

    const handleCloseTransferDetailDialog = () => {
        setCurTxIndex(-1)
        setOpenTransferDetailInfo(false)
    }
    const handleOpenTransferMethodDialog = (row, index) => {
        setTransferMethodData(row)
        setCurTxIndex(index)
        setOpenTransferMethodInfo(true)
    }

    const handleCloseTransferMethodDialog = () => {
        setCurTxIndex(-1)
        setOpenTransferMethodInfo(false)
    }

    const updateClientSide = (option, primeTrustRawData) => {
        const newData = { ...primeTrustRawData }

        // Get client associated with selected option (should be list of 1 element)
        const ptClient = clients.filter(client => client.clientCopperId === option?.id)

        newData.adamClients = ptClient
        updateRawPrimeTrustEntry(primeTrustRawData.id, newData)
    }

    const handleClientChange = async (option, primeTrustRawData) => {
        await setPrimeTrustClient(primeTrustRawData.id, option?.id)
        .then(res => {
            if(res) {
                updateClientSide(option, primeTrustRawData)
            }
            else {
                console.error('Something went wrong while updating Prime Trust transaction')
            }
        })
    }

    const getPrimeTrustClientName = ptRawData => {
        const [ ptClient ] = ptRawData.adamClients
        return ptClient ? ptClient.clientPrimaryName : null
    }

    const RawPrimetrustDataRow = (rawPrimetrustDataRow, index) => {
        // console.log('rawPrimetrustDataRow', rawPrimetrustDataRow)
        // Prepare formatter for currency formatting; commas, dollar-signs..
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{formatter.format(rawPrimetrustDataRow.amount)}</TableCell>
                <TableCell align='left'>{(rawPrimetrustDataRow.funds_transfer_type === 'internal') ? 'primex/internal' : rawPrimetrustDataRow.funds_transfer_type}</TableCell>
                <TableCell align='left'>{moment(rawPrimetrustDataRow.tx_created_at).format('L')}</TableCell>
                <TableCell align='left'>{moment(rawPrimetrustDataRow.settled_on).format('L')}</TableCell>
                <TableCell align='left'>{rawPrimetrustDataRow.funds_transfer ? rawPrimetrustDataRow.funds_transfer.funds_source_name : ''}</TableCell>
                <TableCell align='left'>{rawPrimetrustDataRow.funds_transfer ? rawPrimetrustDataRow.funds_transfer.reference : ''}</TableCell>
                <TableCell align='left'>
                    <ClientAutocompleteSelector
                        clients={clients}
                        value={getPrimeTrustClientName(rawPrimetrustDataRow)}
                        onChange={(e, v) => handleClientChange(v, rawPrimetrustDataRow)}
                        onClear={() => handleClientChange(null, rawPrimetrustDataRow)}
                    />
                </TableCell>
                <TableCell align='center'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTransferDetailDialog(rawPrimetrustDataRow.funds_transfer, index)}
                        disabled={!rawPrimetrustDataRow.funds_transfer}
                    >
                        <InfoIcon color={rawPrimetrustDataRow.funds_transfer? "success" : "disabled"}/>
                    </IconButton>
                </TableCell>
                <TableCell align='center'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        disabled={!rawPrimetrustDataRow.funds_transfer_methods}
                        onClick={() => handleOpenTransferMethodDialog(rawPrimetrustDataRow.funds_transfer_methods, index)}
                    >
                        <InfoIcon color={rawPrimetrustDataRow.funds_transfer_methods? "success": "disabled"}/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const primetrustTable = rawPrimetrust.map((rawPrimetrustDataRow,index) => RawPrimetrustDataRow(rawPrimetrustDataRow,index))
    if(loading) return <CircularProgress />
    else if (rawPrimetrust.length === 0 && !loading) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Transfer Type</TableCell>
                                <TableCell align="left">TX Created</TableCell>
                                <TableCell align="left">TX Settled</TableCell>
                                <TableCell align="left">Funds Source Name</TableCell>
                                <TableCell align="left">Reference</TableCell>
                                <TableCell align="left">Client</TableCell>
                                <TableCell>Funds Transfer Detail</TableCell>
                                <TableCell>Funds Transfer Method Detail</TableCell>
                                {/* <TableCell align="left"/> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {primetrustTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTransferDetailInfo} 
                closeDialog={handleCloseTransferDetailDialog}
                data={transferData}
                title={"Prime trust funds transfer detail"}
            />
            <TransactionInfoModal 
                openDialog={openTransferMethodInfo} 
                closeDialog={handleCloseTransferMethodDialog}
                data={transferMethodData}
                title={"Prime trust funds transfer method detail"}
            />
        </div>
    );
}

export default RawPrimetrustData;