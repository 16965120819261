import React, { useEffect, useState } from 'react';
import './B2C2.css'
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useDropzone} from 'react-dropzone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorAlerts from '../components/ErrorAlert';
// const fileTypes = ["CSV", "XLSM"];
const transferCsvHeader = [
    {
        label: 'type',
        key: 'type'
    },
    {
        label: 'ref_data_exchange',
        key: 'ref_data_exchange'
    },
    {
        label: 'base_asset_code',
        key: 'base_asset_code'
    },
    {
        label: 'base_asset_amount',
        key: 'base_asset_amount'
    },
    {
        label: 'fee_asset_code',
        key: 'fee_asset_code'
    },
    {
        label: 'fee_asset_amount',
        key: 'fee_asset_amount'
    },
    {
        label: 'transfer_start_ts',
        key: 'transfer_start_ts'
    },
    {
        label: 'transfer_complete_ts',
        key: 'transfer_complete_ts'
    },{
        label: 'blockchain_transaction_id',
        key: 'blockchain_transaction_id'
    },
    {
        label: 'blockchain_address',
        key: 'blockchain_address'
    },
    {
        label: 'transfer_id',
        key: 'transfer_id'
    },
    {
        label: 'from_address',
        key: 'from_address'
    },
    {
        label: 'to_address',
        key: 'to_address'
    },
    {
        label: 'contract_address',
        key: 'contract_address'
    },
    {
        label: 'counterparty',
        key: 'counterparty'
    },
    {
        label: 'notes',
        key: 'notes'
    },
    {
        label: 'tags',
        key: 'tags'
    },
]
const tradeCsvHeaders = [
    {
        label: 'type',
        key: 'type'
    },
    {
        label: 'ref_data_exchange',
        key: 'ref_data_exchange'
    },
    {
        label: 'base_asset_code',
        key: 'base_asset_code'
    },
    {
        label: 'base_asset_amount',
        key: 'base_asset_amount'
    },
    {
        label: 'counter_asset_code',
        key: 'counter_asset_code'
    },
    {
        label: 'counter_asset_amount',
        key: 'counter_asset_amount'
    },
    {
        label: 'fee_asset_code',
        key: 'fee_asset_code'
    },
    {
        label: 'fee_asset_amount',
        key: 'fee_asset_amount'
    },
    {
        label: 'rebate_asset_code',
        key: 'rebate_asset_code'
    },
    {
        label: 'rebate_asset_amount',
        key: 'rebate_asset_amount'
    },
    {
        label: 'rate',
        key: 'rate'
    },
    {
        label: 'trade_complete_ts',
        key: 'trade_complete_ts'
    },
    {
        label: 'order_id',
        key: 'order_id'
    },
    {
        label: 'trade_id',
        key: 'trade_id'
    },
    {
        label: 'counterparty',
        key: 'counterparty'
    },
    {
        label: 'notes',
        key: 'notes'
    },
    {
        label: 'tags',
        key: 'tags'
    },
]
const newCsvHeaders = [
    {
        label: 'type',
        key: 'type'
    },
    {
        label: 'sub_type',
        key: 'sub_type'
    },
    {
        label: 'ref_data_exchange',
        key: 'ref_data_exchange'
    },
    {
        label: 'asset',
        key: 'asset'
    },
    {
        label: 'amount',
        key: 'amount'
    },
    {
        label: 'counter_asset_code',
        key: 'counter_asset_code'
    },
    {
        label: 'counter_asset_amount',
        key: 'counter_asset_amount'
    },
    {
        label: 'fee_asset_code',
        key: 'fee_asset_code'
    },
    {
        label: 'fee_asset_amount',
        key: 'fee_asset_amount'
    },
    {
        label: 'rebate_asset_code',
        key: 'rebate_asset_code'
    },
    {
        label: 'rebate_asset_amount',
        key: 'rebate_asset_amount'
    },
    {
        label: 'rate',
        key: 'rate'
    },
    {
        label: 'txn_complete_ts',
        key: 'txn_complete_ts'
    },
    {
        label: 'transaction_id',
        key: 'transaction_id'
    },
    {
        label: 'order_id',
        key: 'order_id'
    },
    {
        label: 'from_address',
        key: 'from_address'
    },
    {
        label: 'to_address',
        key: 'to_address'
    },
    {
        label: 'contract_address',
        key: 'contract_address'
    },
    {
        label: 'blockchain_transaction_id',
        key: 'blockchain_transaction_id'
    },
    {
        label: 'blockchain_address',
        key: 'blockchain_address'
    },
    {
        label: 'counterparty',
        key: 'counterparty'
    },
    {
        label: 'notes',
        key: 'notes'
    },
    {
        label: 'tags',
        key: 'tags'
    },
]
const CRYPTO_SYMBOL_TO_LUKKA = {
    'BTC': 'XBT',
    'LTC': 'XLT',
    'DOT': 'PDT',
    'BSV': 'BCHSV',
    'LEO': 'LEOX',
    'ATOM': 'COS',
    'DAI': 'MCD',
    'VET': 'VEC',
    'UNI': 'UNISW',
    'SNX': 'HVV',
    'COMP': 'COMPG',
    'FTT': 'FTTX',
    'BAT': 'BTT',
    'BTT': 'BTR',
    'OCEAN': 'OCEANT',
    'CELO': 'CLGLD',
    'KNC': 'KNK',
    'STX': 'BSK',
    'BTG': 'BCG',
    'REV': 'REVN',
    'LUNA': 'LNA',
    'EGLD': 'ERD',
    'XNO': 'XRB',
    'SOL': 'SOLN'
}
const FIAT_OPTIONS = {
    'USD': 'USD',
    'EUR': 'EUR',
    'CAD': 'CAD',
    'GBP': 'GBP'
}
// const B2C2RequiredHeaders = [
//     "ID","Currency","Amount","Date"
// ]
const B2C2RequiredHeaders = [
    'ID', 'Order ID', 'Client Order ID', 'User', 'Price', 'Quantity', 'Side', 'Instrument', 'CFD Contract ID', 'Executing unit', 'Date'
]
function B2C2() {
    const [files, setFiles] = useState([]);
    const [formatted, setFormatted] = useState([]);
    const [formattedTransfer, setFormattedTransfer] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({})
    const [steps, setSteps] = useState(['Imported', 'Processed'])
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false)
    // HEADER VALIDATION
    const [isHeaderValid, setIsHeaderValid] = useState(true);
    //const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
    const fileReader = new FileReader();
    // new drop zone functions
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: e => handleChange(e),
        getFilesFromEvent: e => handleChange(e),
        disabled: files.length > 0
    });
    const validatingImportedHeader = (importedHeader) => {
        const isValid = B2C2RequiredHeaders.every(element => {
            return importedHeader.includes(element);
        });
        return isValid;
    }
    const csvToJson = (csv) => {
        let array = csv.split("\n");
        let result = [];
        console.log(array[0])
        let headers = array[0].split(",").map(header => header.replace(/['"]+/g, ''))
        console.log(headers)
        if(!validatingImportedHeader(headers)) {
            setIsHeaderValid(false)
        }
        for (let i = 1; i < array.length; i++) {
            console.log(array[i])
            let obj = {}
            let str = array[i]
            let s = ''
            let flag = 0
            for (let ch of str) {
                if (ch === '"' && flag === 0) {
                flag = 1
                }
                else if (ch === '"' && flag == 1) flag = 0
                if (ch === ',' && flag === 0) ch = '|'
                if (ch !== '"') s += ch
            }
            let properties = s.split("|")
            for (let j in headers) {
                if (properties[j].includes(",")) {
                obj[headers[j]] = properties[j]
                    .split(",").map(item => item.trim())
                }
                else obj[headers[j]] = properties[j]
            }
            result.push(obj)
        }
        return result;
    }
    // function toISOLocal(d) {
    //     let z  = n =>  ('0' + n).slice(-2);
    //     return d.getFullYear() + '-'
    //             + z(d.getMonth()+1) + '-' +
    //             z(d.getDate()) +
    //             ' '+
    //             z(d.getHours()) + ':'  + 
    //             z(d.getMinutes()) + ':' +
    //             z(d.getSeconds())
    // }
    const formattingImported = (dataArray) => {
        let result = []
        for(let i = 0; i <dataArray.length; i++) {
            let curRow = {}
            curRow.type = dataArray[i].Side === 'sell' ? 'MarginSell' : 'MarginBuy';
            curRow.ref_data_exchange = '';
            let assetPair = dataArray[i]['Instrument'].split('.')[0]
            curRow.base_asset_code = assetPair.slice(0, -3) === 'UST' ? 'USDT' : assetPair.slice(0, -3)
            curRow.base_asset_amount = dataArray[i].Quantity
            curRow.counter_asset_code = assetPair.slice(-3)
            curRow.counter_asset_amount = dataArray[i].Price * dataArray[i].Quantity

                // if(dataArray[i-1] && dataArray[i].Reference == dataArray[i-1].Reference){
                //     curRow.counter_asset_code = CRYPTO_SYMBOL_TO_LUKKA[dataArray[i-1].Currency] ?? dataArray[i-1].Currency
                //     curRow.counter_asset_amount = Math.abs(dataArray[i-1].Amount)
                // }
                // else if(dataArray[i].Reference == dataArray[i+1].Reference){
                //     curRow.counter_asset_code = CRYPTO_SYMBOL_TO_LUKKA[dataArray[i+1].Currency] ?? dataArray[i+1].Currency
                //     curRow.counter_asset_amount = Math.abs(dataArray[i+1].Amount)
                // }
            curRow.fee_asset_code = ''
            curRow.fee_asset_amount = ''
            curRow.rebate_asset_code = ''
            curRow.rebate_asset_amount = ''
            curRow.rate = ''
            console.log(dataArray[i].Date)
            console.log(new Date(dataArray[i].Date))
            curRow.trade_complete_ts = new Date(dataArray[i].Date).toISOString()
            curRow.order_id = dataArray[i]['Order ID']
            curRow.trade_id = dataArray[i].ID
            curRow.counterparty = ''
            curRow.notes = ''
            curRow.tags = ''
            result.push(curRow)
                // if(Object.values(CRYPTO_SYMBOL_TO_LUKKA).indexOf(curRow.counter_asset_code) === -1){
                //     result.push(curRow)
                // }
        }
        return result
    }								
    // const formattingImported = (dataArray) => {
    //     console.log(dataArray)
    //     let result = []
    //     let transferResult = [];
    //     for(let i = 0; i <dataArray.length; i++) {
    //         //console.log(dataArray[i].ID)
    //         if(dataArray[i].Type === 'trade'){
    //             // console.log(dataArray[i].ID)
    //             let curRow = {}
    //             curRow.type = Number(dataArray[i].Amount) > 0 ? 'Buy' : 'Sell';
    //             curRow.ref_data_exchange = '';
    //             curRow.base_asset_code = CRYPTO_SYMBOL_TO_LUKKA[dataArray[i].Currency] ?? dataArray[i].Currency
    //             curRow.base_asset_amount = Math.abs(dataArray[i].Amount)
    //             if(dataArray[i-1] && dataArray[i].Reference == dataArray[i-1].Reference){
    //                 curRow.counter_asset_code = CRYPTO_SYMBOL_TO_LUKKA[dataArray[i-1].Currency] ?? dataArray[i-1].Currency
    //                 curRow.counter_asset_amount = Math.abs(dataArray[i-1].Amount)
    //             }
    //             else if(dataArray[i].Reference == dataArray[i+1].Reference){
    //                 curRow.counter_asset_code = CRYPTO_SYMBOL_TO_LUKKA[dataArray[i+1].Currency] ?? dataArray[i+1].Currency
    //                 curRow.counter_asset_amount = Math.abs(dataArray[i+1].Amount)
    //             }
    //             curRow.fee_asset_code = ''
    //             curRow.fee_asset_amount = ''
    //             curRow.rebate_asset_code = ''
    //             curRow.rebate_asset_amount = ''
    //             curRow.rate = ''
    //             curRow.trade_complete_ts = new Date(dataArray[i].Date).toISOString()
    //             curRow.order_id = dataArray[i].Reference
    //             curRow.trade_id = dataArray[i].ID
    //             curRow.counterparty = ''
    //             curRow.notes = ''
    //             curRow.tags = ''
    //             if(Object.values(CRYPTO_SYMBOL_TO_LUKKA).indexOf(curRow.counter_asset_code) === -1){
    //                 result.push(curRow)
    //             }
                
    //         }
    //         else {
    //             let curRow = {}
    //             curRow.type = Number(dataArray[i].Amount) > 0 ? 'Deposit' : 'Withdrawal';
    //             curRow.ref_data_exchange = ''
    //             curRow.base_asset_code = dataArray[i].Currency
    //             curRow.base_asset_amount = Math.abs(dataArray[i].Amount)
    //             curRow.fee_asset_code = ''
    //             curRow.fee_asset_amount = ''
    //             curRow.transfer_start_ts = ''
    //             curRow.transfer_complete_ts = new Date(dataArray[i].Date).toISOString()
    //             curRow.blockchain_transaction_id = ''
    //             curRow.blockchain_address = ''
    //             curRow.transfer_id = dataArray[i].ID
    //             curRow.from_address = ''
    //             curRow.to_address = ''
    //             curRow.contract_address = ''
    //             curRow.counterparty = ''
    //             curRow.notes = ''
    //             curRow.tags = ''
    //             transferResult.push(curRow)
    //         }
    //     }
    //     return {
    //         trade: result,
    //         transfer: transferResult
    //     }
    //     // return result
    // }
    const fileValidation = (filename) => {
        const allowedExtensions =
                    /(\.csv|\.xlsx|\.xlsm|\.xls)$/i;
        if(!allowedExtensions.exec(filename)) {
            return false
        }
        else return true
    }
    const handleChange = async(e) => {
        console.log(e)
        const fileList = [...files]
        let importedFiles //= e.target.files
        if(!e.target) {
            importedFiles = await e[0].getFile()
            console.log(importedFiles)
            let isValid = fileValidation(importedFiles.name)
            if(isValid) {
                fileList.push(importedFiles);
            }
            else {
                alert('Invalid file type')
            }
        }
        else {
            importedFiles = e.target.files
            console.log(importedFiles)
            let isValid = fileValidation(importedFiles[0].name)
            if(isValid) {
                fileList.push(importedFiles[0]);
            }
            else {
                alert('Invalid file type')
            }
        }
        setFiles(fileList)
        handleProcessingFile(fileList)
        // if (files[0]) {
        //     fileReader.onload = function (event) {
        //         const csvOutput = event.target.result;
        //         const wb = XLSX.read(csvOutput, { type: "binary" });
        //         const wsname = wb.SheetNames[2];
        //         const ws = wb.Sheets[wsname];
        //         const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        //         console.log(data);
        //         let result = csvToJson(data)
        //         console.log(result)
        //         setSheetData(result)
        //         let formattedResult = formattingImported(result)
        //         setFormatted(formattedResult)
        //     };

        //     fileReader.readAsBinaryString(files[0]);
        // }
    };
    const displayProcessedContent = () => {
        setShowTable(true)
    }
    const handleProcessingFile = (fileList) => {
        setLoading(true)
        fileReader.onload = function (event) {
            const csvOutput = event.target.result;
            const wb = XLSX.read(csvOutput, { type: "binary",cellText:false, cellDates:true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1, dateNF: 'MM"/"DD"/"YYYY HH":"mm":"ss' });
            console.log(data);
            let result = csvToJson(data)
            // console.log(result)
            // setSheetData(result)
            let formattedResult = formattingImported(result)
            console.log(formattedResult)

            setFormatted(formattedResult)
            //setFormattedTransfer(formattedResult.transfer)
            setLoading(false)
        };
        fileReader.readAsBinaryString(fileList[0]);
    }
    const totalSteps = () => {
        return steps.length;
    }
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    
    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };
    
    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    const handleDeleteImported = (file, index) => {
        console.log(file)
        console.log(index)
        let filesArr = [...files]
        let fileIndex = filesArr.filter((f, id) => f === file && id === index)
        if(fileIndex[0]) {
            filesArr.splice(index, 1)
        }
        setFiles(filesArr)
        setFormatted([])
    }
    useEffect(() => {
        console.log(formatted.length)
    }, [formatted])
    console.log(formatted)
    return (
        <div>
            B2C2
            {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
            {formatted.length > 0 ? <CSVLink data={formatted} headers={newCsvHeaders}>
                Download me
            </CSVLink> : null} */}
            <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep} style={{ marginBottom: 10}}>
                {steps.map((label, index) => (
                index === 1 ? formatted.length > 0 ?
                <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                        {label} {loading? <CircularProgress /> : null}
                    </StepButton>
                </Step>
                : <Step key={label} completed={completed[index]} disabled>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                    </StepButton>
                </Step>
                :<Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                </StepButton>
            </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
                ) : (
                <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                    {activeStep === 0 ? 
                        <div className='componentContainer'>
                            <section className="container">
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                <aside>
                                    {files.length> 0 ? files.map((file, index) => 
                                    <ul>
                                        {file.name}
                                        <IconButton aria-label="delete" size="small" onClick={() => handleDeleteImported(file, index)}><DeleteIcon /></IconButton>
                                    </ul>) : null}
                                </aside>
                            </section>
                        </div>
                        : <div className='processedContainer'>
                            <div className='buttonGroup'>
                                <Button variant="contained" color='secondary' endIcon={<DownloadOutlinedIcon />}>
                                    <CSVLink data={formatted} headers={tradeCsvHeaders} style={{textDecoration: 'none', color: 'white'}} filename={'B2C2_LUKKA_TRADE.csv'}>
                                        Download Trade
                                    </CSVLink>
                                </Button>
                                {/* <Button variant="contained" endIcon={<DownloadOutlinedIcon />}>
                                    <CSVLink data={formattedTransfer} headers={transferCsvHeader} style={{textDecoration: 'none', color: 'white'}} filename={'B2C2_LUKKA_TRANSFER.csv'}>
                                        Download Transfer
                                    </CSVLink>
                                </Button> */}
                            </div>
                            {/* {showTable ? 
                            <TableContainer component={Paper} sx={{maxHeight: 500, marginTop: 4}}>
                                {loading ? <LinearProgress color="success"/> : null}
                                <Table sx={{ minWidth: 650}} aria-label="simple table" size="small">
                                    <TableHead>
                                    <TableRow>
                                        {newCsvHeaders.map(h => <TableCell>{h.label}</TableCell>)}
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {formatted.slice(0, 50).map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {newCsvHeaders.map(h => 
                                            h.label === 'transaction_id' ? 
                                            <Tooltip title={row[h.label]} placement="top"><TableCell>{row[h.label].substr(0, 7) + '...' + row[h.label].substr(row[h.label].length-7, row[h.label].length)}</TableCell></Tooltip>
                                            :h.label === 'txn_complete_ts' ? 
                                            <Tooltip title={row[h.label]} placement="top"><TableCell>{row[h.label].substr(0, 10)}</TableCell></Tooltip>
                                            :<TableCell>{row[h.label]}</TableCell>)}
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : null} */}
                        </div>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {activeStep === 1 ? <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button> : null}
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep === 0 ? <Button onClick={handleNext} sx={{ mr: 1 }} disabled={formatted.length === 0}>
                        Next
                    </Button> : null}
                    {/* <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext} sx={{ mr: 1 }} disabled={formatted.length === 0}>
                        Next
                    </Button> */}
                    {/* {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                        <Typography variant="caption" sx={{ display: 'inline-block' }}>
                            Step {activeStep + 1} already completed
                        </Typography>
                        ) : (
                        <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                            ? 'Finish'
                            : 'Complete Step'}
                        </Button>
                        ))} */}
                    </Box>
                </React.Fragment>
                )}
            </div>
            </Box>
            <ErrorAlerts 
                show={!isHeaderValid} 
                errorMessage={"Imported File has wrong format, make sure your imported file contains 'ID', 'Currency', 'Date', 'Amount' columns"}
                handleClose={()=> setIsHeaderValid(true)}
            />
        </div>
    );
}

export default B2C2;