import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClientMapping from './ClientMapping';
import Fireblocks from './Fireblocks';
import PrimeTrust from './PrimeTrust';
import BCB from './BCB';
import ConnectFirst from './ConnectFirst';
import Finclusive from './Finclusive';
import Fortress from './Fortress';
import Openpayd from './Openpayd';
import Talos from './Talos';
import FireblocksWalletMapping from './FireblocksWalletMapping';
import TradingPairManager from './TradingPairsAdmin';
import TradeCommission from './TradeCommission';
import TradeReport from './TradeReport';
import Balances from './Balances';
import WAC from './WAC';
import MergedTrades from './MergedTradeLPGenerator';
import Aquanow from './LPs/Aquanow';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Deltec from './Deltec';

// menu items
const BANKS = ['PrimeTrust', 'BCB', 'ConnectFirst', 'Finclusive', 'Fortress', 'Openpayd', 'Deltec']
const LPS = ['Talos', 'Aquanow']
const CLIENTDROP = ['Client Mapping', 'Fireblock Wallet', 'Client Commission' ]
const FINANCE = ['Balances', "Wac"]
const TRADE = ['Trade Ticket', 'Trading Pairs', 'Trade Report' ]

/**
 * ADAM project index page
 * @param curUser   Current user object
 * @param token     Access token
 * @returns {JSX.Element}
 */
const Adam = ({curUser, token}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElForLP, setAnchorElForLP] = useState(null);
    const [selectedBank, setSelectedBank] = useState(0);
    const [selectedLP, setSelectedLP] = useState(0)

    const [anchorElForClient, setAnchorElForClient] = useState(null)
    const [selectedClient, setSelectedClient] = useState(0)

    const [anchorElForFinance, setAnchorElForFinance] = useState(null)
    const [selectedFinance, setSelectedFinance] = useState(0)

    const [anchorElForTrade, setAnchorElForTrade] = useState(null)
    const [selectedTrade, setSelectedTrade] = useState(0)

    // Tab style
    const tabStyle = {
        color: '#FFCC36',
        '&.Mui-selected': {
            color: 'white',
            borderBottom: 'white'
        },
    }

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    // for bank selection
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // for bank selection
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // for bank selection
    const handleMenuItemClick = (index) => {
        // Perform actions based on the selected menu item
        // console.log('Selected LP menu item:', index);
        setSelectedBank(index)
        handleMenuClose();
    };

    // for LP selection
    const handleLPMenuOpen = (event) => {
        setAnchorElForLP(event.currentTarget);
    };

    // for LP selection
    const handleLPMenuClose = () => {
        setAnchorElForLP(null);
    };

    // for LP selection
    const handleLPMenuItemClick = (index) => {
        // Perform actions based on the selected menu item
        console.log('Selected menu item:', index);
        setSelectedLP(index)
        handleLPMenuClose();
    };

    // For client mapping dropdown
    const handleClientMenuOpen = (event) => {
        setAnchorElForClient(event.currentTarget)
    }

    // For client mapping dropdown
    const handleClientMenuClose = () => {
        setAnchorElForClient(null)
    }

    // For client mapping dropdown
    const handleClientItemClick = (index) => {
        setSelectedClient(index)
        handleClientMenuClose()   
    }

    // For Finance tab
    const handleFinanceMenuOpen = (event) => {
        setAnchorElForFinance(event.currentTarget)
    }

    // For Finance tab
    const handleFinanceMenuClose = () => {
        setAnchorElForFinance(null)
    }

    // For Finance tab
    const handleFinanceItemClick = (index) => {
        setSelectedFinance(index)
        handleFinanceMenuClose()
    }

    // For Trade tab
    const handleTradeMenuOpen = (event) => {
        setAnchorElForTrade(event.currentTarget)
    }

    // For Trade tab
    const handleTradeMenuClose = () => {
        setAnchorElForTrade(null)
    }

    // For Trade tab
    const handleTradeItemClick = (index) => {
        setSelectedTrade(index)
        handleTradeMenuClose()
    }

    return (
        <div>
            <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{backgroundColor: 'black', paddingLeft: '1em', borderTop: 'solid #FFCC36 1px'}}>
                
                <Tab label={CLIENTDROP[selectedClient]}
                    onClick={handleClientMenuOpen}
                    icon={<ExpandMoreIcon />}
                    iconPosition="end"
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    sx={tabStyle}
                    />
              
                <Tab label={"Finance- " + FINANCE[selectedFinance]}
                    onClick={handleFinanceMenuOpen}
                    icon={<ExpandMoreIcon />}
                    iconPosition="end"
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    sx={tabStyle}
                    />

                <Tab label="Fireblocks" 
                    sx={tabStyle}
                    />

                <Tab 
                    label={'Banks-' + BANKS[selectedBank]}
                    onClick={handleMenuOpen}
                    icon={<ExpandMoreIcon />}
                    iconPosition="end"
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    sx={tabStyle}
                    />
                <Tab 
                    label={'LP-' + LPS[selectedLP]}
                    onClick={handleLPMenuOpen}
                    icon={<ExpandMoreIcon />}
                    iconPosition="end"
                    aria-controls="dropdown-menu-lp"
                    aria-haspopup="true"
                    sx={tabStyle}
                    />

                <Tab label={TRADE[selectedTrade]}
                    onClick={handleTradeMenuOpen}
                    icon={<ExpandMoreIcon />}
                    iconPosition="end"
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    sx={{...tabStyle, color: '#FFCC36'}} // @TODO: why custom color?
                    />

            </Tabs>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{color: 'white', backgorundColor: 'green'}}
            >
                <MenuItem onClick={() => handleMenuItemClick(0)}>PrimeTrust</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(1)}>BCB</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(2)}>ConnectFirst</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(3)}>Finclusive</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(4)}>Fortress</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(5)}>Openpayd</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(6)}>Deltec</MenuItem>
            </Menu>
            <Menu
                id="dropdown-menu-lp"
                anchorEl={anchorElForLP}
                open={Boolean(anchorElForLP)}
                onClose={handleLPMenuClose}
            >
                <MenuItem onClick={() => handleLPMenuItemClick(0)}>TALOS</MenuItem>
                <MenuItem onClick={() => handleLPMenuItemClick(1)}>AQUANOW</MenuItem>
            </Menu>

            <Menu
                id="dropdown-menu-client"
                anchorEl={anchorElForClient}
                open={Boolean(anchorElForClient)}
                onClose={handleClientMenuClose}
            >
                <MenuItem onClick={() => handleClientItemClick(0)} color='black'>Client Mapping</MenuItem>
                <MenuItem onClick={() => handleClientItemClick(1)}>Fireblocks Wallet</MenuItem>
                <MenuItem onClick={() => handleClientItemClick(2) }>Client Commission</MenuItem>
            </Menu>

            <Menu
                id="dropdown-menu-finance"
                anchorEl={anchorElForFinance}
                open={Boolean(anchorElForFinance)}
                onClose={handleFinanceMenuClose}
            >
                <MenuItem 
                onClick={() => handleFinanceItemClick(0)}>Balances</MenuItem>
                <MenuItem onClick={() => handleFinanceItemClick(1)}>WAC</MenuItem>
            </Menu>

            <Menu
                id="dropdown-menu-trade"
                anchorEl={anchorElForTrade}
                open={Boolean(anchorElForTrade)}
                onClose={handleTradeMenuClose}
            >
                <MenuItem onClick={() => handleTradeItemClick(0)}>Trade Ticket</MenuItem>
                <MenuItem onClick={() => handleTradeItemClick(1)}>Trading Pairs</MenuItem>
                <MenuItem onClick={() => handleTradeItemClick(2)}>Trade Report</MenuItem>
            </Menu>


            {/* Render the content based on the selected tab */}
            {tabIndex === 0 && selectedClient === 0 && <ClientMapping accessToken={token} />}
            {tabIndex === 0 && selectedClient === 1 && <FireblocksWalletMapping accessToken={token} />}
            {tabIndex === 0 && selectedClient === 2 && <TradeCommission curUser={curUser} token={token} /> }
            {tabIndex === 1 && selectedFinance === 0 && <Balances accessToken={token}/>}
            {tabIndex === 1 && selectedFinance === 1 && <WAC curUser={curUser} token={token} />}
            {tabIndex === 2 && <Fireblocks accessToken={token} />}
            {tabIndex === 3 && selectedBank === 0 && <PrimeTrust accessToken={token} />}
            {tabIndex === 3 && selectedBank === 1 && <BCB accessToken={token} />}
            {tabIndex === 3 && selectedBank === 2 && <ConnectFirst accessToken={token} />}
            {tabIndex === 3 && selectedBank === 3 && <Finclusive accessToken={token} /> }
            {tabIndex === 3 && selectedBank === 4 && <Fortress accessToken={token} />}
            {tabIndex === 3 && selectedBank === 5 && <Openpayd accessToken={token} />}
            {tabIndex === 3 && selectedBank === 6 && <Deltec accessToken={token} />}
            {tabIndex === 4 && selectedLP === 0 && <Talos accessToken={token} />}
            {tabIndex === 4 && selectedLP === 1 && <Aquanow accessToken={token} />}
            {tabIndex === 5 && selectedTrade === 0 && <MergedTrades curUser={curUser} showTable={true} token={token}/>}
            {tabIndex === 5 && selectedTrade === 1 && <TradingPairManager accessToken={token} />}
            {tabIndex === 5 && selectedTrade === 2 && <TradeReport />}
        </div>
    );
};

// Export the component as the default object
export default withAuthenticationRequired(Adam, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});