import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import copy from 'copy-to-clipboard';
import { Stack } from '@mui/material';
import { rates } from '../TradeTicketGenerator/rates';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PreviewDialog({open, submitTicket, handleClose,  tradingPair, date, decimalPlace, liquidityProvider, amountTraded, price, totalNotional, action  }) {
    const [isCopied, setIsCopied] = React.useState(false)
    // console.log(totalNotional);
    /**
     * Handles copying the trade ticket summary to the clipboard.
     */
    const handleCopyTradeTicket = () => {
        setIsCopied(false)
        let ticket = `*Trade Summary* \n`+
            `Date: ${date} \n` +
            `Symbol: ${tradingPair.replace(/\//g, '-')} \n` +
            `OrderType: ${action} \n` +
            `Amount Traded: ${parseFloat(amountTraded).toLocaleString(undefined, rates[tradingPair.split('/')[0]]? {maximumFractionDigits: rates[tradingPair.split('/')[0]].decimal, minimumFractionDigits: rates[tradingPair.split('/')[0]].decimal}: {maximumFractionDigits:4, minimumFractionDigits:4})} ${tradingPair.split('/')[0]} \n` +
            `Rate: ${parseFloat(price).toLocaleString(undefined, rates[tradingPair.split('/')[0]]? {maximumFractionDigits: rates[tradingPair.split('/')[0]][tradingPair], minimumFractionDigits: rates[tradingPair.split('/')[0]][tradingPair]}: {maximumFractionDigits:4, minimumFractionDigits:4})} ${tradingPair} \n` +
            `Total Notional: ${parseFloat(totalNotional).toLocaleString(undefined, rates[tradingPair.split('/')[1]]? {maximumFractionDigits: rates[tradingPair.split('/')[1]].decimal, minimumFractionDigits: rates[tradingPair.split('/')[1]].decimal} : {maximumFractionDigits:4, minimumFractionDigits:4})} ${tradingPair.split('/')[1]} \n` +
            `LP: ${liquidityProvider} \n`
        copy(ticket)
        setIsCopied(true)
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={1}>
                        {isCopied?
                        <Button color='success' onClick={() => handleCopyTradeTicket()}>Copied!</Button>
                        :<Button color='primary' onClick={() => handleCopyTradeTicket()}>Copy Trade Ticket</Button>}
                    </Stack>
                    <Stack>
                        <Typography id="modal-modal-description" sx={{ mt: 2, alignSelf: 'center' }}>
                            <p style={{margin: 0}}>*Trade Summary*</p>
                            <p style={{margin: 0}}>Date: {date}</p>
                            <p style={{margin: 0}}>Symbol: {tradingPair.replace(/\//g, '-')}</p>
                            <p style={{margin: 0}}>OrderType: {action}</p>
                            <p style={{margin: 0}}>Amount Traded: {parseFloat(amountTraded).toLocaleString(undefined, rates[tradingPair.split('/')[0]]? {maximumFractionDigits: rates[tradingPair.split('/')[0]].decimal, minimumFractionDigits: rates[tradingPair.split('/')[0]].decimal}: {maximumFractionDigits:4, minimumFractionDigits:4})} {tradingPair.split('/')[0]}</p>
                            <p style={{margin: 0}}>Rate: {parseFloat(price).toLocaleString(undefined, rates[tradingPair.split('/')[0]]? {maximumFractionDigits: rates[tradingPair.split('/')[0]][tradingPair], minimumFractionDigits: rates[tradingPair.split('/')[0]][tradingPair]}: {maximumFractionDigits:4, minimumFractionDigits:4})} {tradingPair}</p>
                            <p style={{margin: 0}}>Total Notional: {parseFloat(totalNotional).toLocaleString(undefined, rates[tradingPair.split('/')[1]]? {maximumFractionDigits: rates[tradingPair.split('/')[1]].decimal, minimumFractionDigits: rates[tradingPair.split('/')[1]].decimal} : {maximumFractionDigits:4, minimumFractionDigits:4})} {tradingPair.split('/')[1]}</p>
                            <p style={{margin: 0}}>LP: {liquidityProvider}</p>
                            <p style={{margin: 10}}></p>
                        </Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Button variant='contained' color='primary' onClick={submitTicket}>Submit</Button>
                        <Button variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                    </Stack>
                    
                </Box>
            </Modal>
        </div>
    );
}

export default PreviewDialog;