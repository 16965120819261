import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

const WIDTH_SCALE_FACTOR = 5

/**
 * Renders a formatted entry for a client report table.
 * @param {object} tableEntry - The entry object for the table.
 * @param {number} width - The width of the table cell.
 * @returns The formatted table cell component.
 */
function ClientReportFormatEntry({ tableEntry, width }) {

    if(!tableEntry.format) {
        return <TableCell width={WIDTH_SCALE_FACTOR * width} sx={{ wordBreak: 'break-word' }}>{tableEntry}</TableCell>
    }

    // Can be changed to span or div element with className to support more formats 
    return <TableCell>{tableEntry.format?.bold ? <b>{tableEntry.text}</b> : <>{tableEntry.text}</>}</TableCell>
}

export default function ClientReportTable({ tableData }) {
    const { headers, data } = tableData

    return (
        <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
            <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                    <TableRow>
                        {
                            headers.map(header => (
                                <TableCell width={WIDTH_SCALE_FACTOR * header.width}>{header.header}</TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map(row => (
                            <TableRow hover>
                                {
                                    row.map((entry, index) => <ClientReportFormatEntry tableEntry={entry} width={headers[index].width} />)
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}