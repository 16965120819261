import React from 'react';
import Box from '@mui/material/Box';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import './Trader.css'

function Trader(props) {

    return (
        <div className="wrapper">
            Trader page
        </div>
    )
}

export default withAuthenticationRequired(Trader, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});


