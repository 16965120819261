import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import ClientAutocompleteSelector from '../../../components/clientAutocompleteSelector';
import SimpleTablePagination from '../../../components/SimpleTablePagination';

function RawConnectFirstData({rawData,loading, clients, setConnectFirstClient, updateRawConnectFirstEntry, paginationProps}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }

    const updateClientSide = (option, connectFirstRawData) => {
        const newData = { ...connectFirstRawData }

        // Get client associated with selected option (should be list of 1 element)
        const cfClient = clients.filter(client => client.clientCopperId === option?.id)

        newData.adamClients = cfClient
        updateRawConnectFirstEntry(connectFirstRawData.id, newData)
    }

    const handleClientChange = async (option, connectFirstRawData) => {
        await setConnectFirstClient(connectFirstRawData.id, option?.id)
        .then(res => {
            if(res) {
                updateClientSide(option, connectFirstRawData)
            }
            else {
                console.error('Something went wrong while updating Connect First transaction')
            }
        })
    }

    const getConnectFirstClientName = cfRawData => {
        const [ cfClient ] = cfRawData.adamClients
        return cfClient ? cfClient.clientPrimaryName : null
    }

    const RawCFDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)

        let the_description = rawDataRow.description;   // initialize to provided value

        // In Description, remove the redundant TX Type at the beginning, unless it's the only thing there
        if (rawDataRow.description.startsWith(rawDataRow.transaction_type)
            && (rawDataRow.description !== (rawDataRow.transaction_type + ',')))
        {
            the_description = the_description.slice(rawDataRow.transaction_type.length + 1)
        }

        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{moment(rawDataRow.date).format('L')}</TableCell>
                <TableCell align='left'>{rawDataRow.transaction_type}</TableCell>
                <TableCell align='left'>{rawDataRow.amount}</TableCell>
                <TableCell align='left'>{the_description}</TableCell>
                <TableCell align='left'>
                    <ClientAutocompleteSelector 
                        clients={clients}
                        onChange={(e, v) => handleClientChange(v, rawDataRow)}
                        value={getConnectFirstClientName(rawDataRow)}
                        onClear={() => handleClientChange(null, rawDataRow)}
                    />
                </TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    const cfTable = rawData.map((rawDataRow,index) => RawCFDataRow(rawDataRow,index))

    if (rawData.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Date</TableCell>
                                <TableCell>TX Type</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell align="left">Client</TableCell>
                                <TableCell align="left"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cfTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Connect First Transaction Details"}
            />
        </div>
    );
}

export default RawConnectFirstData;