/**
 * TalosService Service
 */
export class TalosService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async getAllTalos(pageSize, pageNum) {
        const response = await fetch(
            this.API_URL + `/taloslp?size=${pageSize}&page=${pageNum}`,
            {
                headers: {
                    // 'Content-Type': 'application/json'
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async getCustomerTalos(pageSize, pageNum) {
        const response = await fetch(
            this.API_URL + `/taloscustomertrades?size=${pageSize}&page=${pageNum}`,
            {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

}