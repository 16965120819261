import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import './Account.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ErrorAlerts from '../components/ErrorAlert';

import { getUserApiKey } from '../api/authService';

function Account(props) {
    const [apiKey, setApiKey]  = useState()
    const [keyVisible, setKeyVisible] = useState(false)
    const [apiKeyError, setApiKeyError] = useState(false)
    const [buttonStyle, setButtonStyle] = useState({})
    // const user = props.user
    // const token = props.token
    // const roles = props.roles 
    // const isTalosUser = props.isTalosUser
    const {user, token, roles, isTalosUser} = props
    // console.log(user)

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            const style = {border: '1px solid rgba(25, 118, 210, 1)', backgroundColor:' rgba(25, 118, 210, 0.04)'}
            setButtonStyle(style)
        } else {
            setKeyVisible(false)
            setButtonStyle({})
        }
    }

    const getKey = async () => {
        // console.log(token)
        // console.log(user.email);
        let res = await getUserApiKey(token, user.email)
        if (!res) {
            setApiKeyError(true)
        }

        const key = res.apiKey.data[0]
        if (key) {
            setApiKey(key)
            setKeyVisible(true)
        }
    }

    return (
        <div className="wrapper">
            <span>Account Information</span>
            <h2>Email: {user.email} </h2>

            {roles.includes("CustomerUser") && (
                <>
                    <Button 
                        variant="outlined" 
                        onClick={() => getKey({ returnTo: window.location.origin })}
                        disabled={!isTalosUser}
                    >
                        Generate New API key
                    </Button>
                    {isTalosUser? (
                            <p>You won't be able to view this key again, so make sure you save it somewhere safe.</p>
                        ): 
                        (
                            <>
                                <p>Please contact your SDM Account Manager to configure your trading access </p>
                                <p>support@securedigitalmarkets.com</p>
                            </>
                        )
                    }
                </>
            )}

            {apiKey && (
                <Dialog
                    open={keyVisible}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Make sure to save your key somewhere safe"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            API KEY: {apiKey.ApiKey}  
                            <br/>
                            SECRET: {apiKey.ApiSecret}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose} 
                            autoFocus
                            sx={buttonStyle}
                            variant='outlined'
                        >
                            I have saved my key
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <ErrorAlerts 
                show={apiKeyError} 
                errorMessage={"Cannot get api key for this user"}
                handleClose={()=> setApiKeyError(false)}
            />
        </div>
    );
}

export default withAuthenticationRequired(Account, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});