import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import FireblocksBal from './FireblocksBal';
import LPBalances from './LPBalances';
import AllBalances from './AllBalances';
import CustomerBalances from './CustomerBalances';
import { BalancesService } from '../../../api/BalancesService';
function Balances({ accessToken }) {
    let balanceService = new BalancesService(accessToken)
    const [activeTab, setActiveTab] = useState(0);
    const [customerData, setCustomerData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getCustomerBalances()
    },[])
    const getCustomerBalances = async() => {
        setLoading(true)
        let res = await balanceService.getCustomerBalances()
        console.log(res)
        setCustomerData(res)
        setLoading(false)
    }
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="Fireblocks" />
                <Tab label="LP Trades" />
                <Tab label="Customer Trades" />
                {/* <Tab label="Inter Companies" /> */}
            </Tabs>
            <div style={{width: '100%'}}>
                {activeTab === 0 && <AllBalances token={accessToken} customerBalance={customerData} customerLoading={loading}/>}
                {activeTab === 1 && <FireblocksBal token={accessToken} />}
                {activeTab === 2 && <LPBalances token={accessToken} />}
                {activeTab === 3 && <CustomerBalances data={customerData} loading={loading}/>}
            </div>
            {/* {activeTab === 2 && <Fireblocks accessToken={token} />} */}
        </Box>
    );
}

export default Balances;