import React, {useState} from 'react';
import moment from 'moment'
import {newCsvHeaders} from './utils/constants'
import { instance } from '../api/authService';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { CSVLink } from "react-csv";
function Enigma(props) {
    const [apiStartDate, setApiStartDate] = useState(moment());
    const [apiEndDate, setApiEndDate] = useState(moment()); 
    const [loading, setLoading] = useState(false);
    const [apiData, setApiData] = useState([]);
    const handleRetrieveData = async() => {
        setLoading(true)
        setApiData([])
        let fromDate = (apiStartDate._d).toISOString().substring(0, 10)
        let toDate = (apiEndDate._d).toISOString().substring(0, 10)
        let result = await instance.get('/getEnigmaTransferTx?dateFrom=' + fromDate + '&dateTo=' + toDate)
        console.log(result)
        if(result){
            setLoading(false)
            result.status === 200 && setApiData(result.data)
        }
    }
    return (
        <div>
            Enigma
            <>                    
                <div style={{ marginTop: '10px'}}>
                    <FormControl style={{ flexDirection: 'row', alignItems: 'center', margin: '5px'}}>
                        <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>Start Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="From"
                                value={apiStartDate}
                                onChange={(newValue) => {
                                    setApiStartDate(newValue);
                                }}
                                maxDate={apiEndDate ? apiEndDate : null}
                                renderInput={(params) => <TextField {...params} />}    
                            />    
                        </LocalizationProvider>            
                    </FormControl>   
                    <FormControl style={{ flexDirection: 'row', alignItems: 'center', margin: '5px'}}>
                        <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>End Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="To"
                                value={apiEndDate}
                                onChange={(newValue) => {
                                    setApiEndDate(newValue);
                                }}
                                minDate={apiStartDate ? apiStartDate : null}
                                maxDate={moment()}
                                renderInput={(params) => <TextField {...params} />}    
                            />
                        </LocalizationProvider>            
                    </FormControl>  
                </div>
                <div style={{margin: 'auto', marginTop: '10px', marginBottom: '10px'}}>
                    {loading ? 
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">Process</LoadingButton>
                    :<Button variant="outlined" onClick={() => handleRetrieveData()}>Process</Button>}
                </div>
                <div style={{margin: 'auto', marginTop: '5px'}}>
                    {apiData.length > 0 ? 
                    <Button variant="contained" endIcon={<DownloadOutlinedIcon />}>
                        <CSVLink 
                            data={apiData} 
                            headers={newCsvHeaders} 
                            style={{textDecoration: 'none', color: 'white'}} 
                            filename={'ENIGMA_LUKKA_' +(apiStartDate._d).toISOString().substring(0, 10) + '_' + (apiEndDate._d).toISOString().substring(0, 10) +'.csv'}
                        >
                            Download CSV
                        </CSVLink>
                    </Button>
                    : null}
                </div>
            </>
        </div>
    );
}

export default Enigma;