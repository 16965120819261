/**
 * Primetrust Service
 */
export class PrimetrustService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async getAllPrimetrust(pageSize, pageNum, accountID) {
        const response = await fetch(
            this.API_URL + `/primetrust?size=${pageSize}&page=${pageNum}${accountID ? '&account=' + accountID : ''}`,
            {
                headers: {
                    // 'Content-Type': 'application/json'
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async setPrimetrustClient(primeTrustID, clientCopperID) {
        const response = await fetch(
            this.API_URL + '/primetrust/setclient', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`
            },
            body: JSON.stringify({
                primeTrustID,
                clientCopperID
            })
        });

        return response.json();
    }

}