import React from 'react';
import './Error.css'

function Error(props) {

    return (
        <div className="wrapper">
            There was an error 
        </div>
    );
}

export default Error;