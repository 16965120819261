import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import SimpleTablePagination from "../../../components/SimpleTablePagination";
import ClientAutocompleteSelector from "../../../components/clientAutocompleteSelector";
import InfoIcon from "@mui/icons-material/Info";
import TransactionInfoModal from "../../../components/transactionInfoModal";

const DeltecInfo = ({
  adamDeltecData,
  paginationProps,
  clients,
  setDeltecClient,
  updateAdamDeltecData,
}) => {
  const [curTxIndex, setCurTxIndex] = useState(-1);
  const [openTxInfo, setOpenTxInfo] = useState(false);
  const [txData, setTxData] = useState();

  const handleOpenTxInfoDialog = (item, index) => {
    setTxData(item);
    setCurTxIndex(index);
    setOpenTxInfo(true);
  };

  const handleCloseTxInfoDialog = () => {
    setOpenTxInfo(false);
    setCurTxIndex(-1);
  };

  const getAdamClientName = (adamDeltecItem) => {
    // Check if adamDeltecItem.adamClients is an array and has at least one element
    if (
      Array.isArray(adamDeltecItem.adamClients) &&
      adamDeltecItem.adamClients.length > 0
    ) {
      const [adamClient] = adamDeltecItem.adamClients;
      return adamClient ? adamClient.clientPrimaryName : null;
    }
    return null; // Return null if adamClients is not an array or is empty
  };

  const handleClientChange = async (clientOption, item) => {
    const clientCopperID = clientOption ? clientOption.id : null;
    try {
      // setDeltecClient now directly returns the data object
      const updatedItemData = await setDeltecClient(
        item.ledger_id,
        clientCopperID
      );

      if (clientOption === null) {
        updatedItemData.adamClients = []; // Clear the adamClients array
      }

      updateAdamDeltecData(updatedItemData);
    } catch (error) {
      console.error("Error updating client:", error);
    }
  };

  return (
    <div>
      <div>
        {adamDeltecData && adamDeltecData.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Deltec Data">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adamDeltecData.map((item, index) => (
                    <TableRow key={index} sx={{ align: "center" }}>
                      <TableCell component="th" scope="row">
                        {new Date(item.timestamp).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="right">{item.amount}</TableCell>
                      <TableCell>
                        {item.description ? item.description : "No description"}
                      </TableCell>
                      <TableCell>
                        <ClientAutocompleteSelector
                          clients={clients}
                          value={getAdamClientName(item)}
                          onChange={(event, newValue) =>
                            handleClientChange(newValue, item)
                          }
                          onClear={() => handleClientChange(null, item)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          sx={{ marginTop: "0.5em" }}
                          onClick={() => handleOpenTxInfoDialog(item, index)}
                        >
                          <InfoIcon color="success" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <SimpleTablePagination paginationProps={paginationProps} />
            </TableContainer>
            <TransactionInfoModal
              openDialog={openTxInfo}
              closeDialog={handleCloseTxInfoDialog}
              data={txData}
              title={"Deltec Transaction Details"}
            />
          </>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default DeltecInfo;
