import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import logo from "../assets/sdm-logo.svg";
import { NavLink } from "react-router-dom";

import "./landing.css";

function Landing(props) {
  return (
    <div className="landing-container">
      <div className="logo-container">
        <img src={logo} alt="sdm-logo" />
        <h2>
          Your personal gateway to crypto markets
        </h2>
        <span>Coming Soon!</span>
        <div  className="get-keys" style={{ textAlign: 'center', marginTop: '1em' }}> 
          <NavLink
            className="headerItem"
            to="/account"
            style={({ isActive }) => {
              return {
                color: isActive ? "#ffef96" : "",
                textDecoration: "underline",
              };
            }}
          >
            Get API Keys
          </NavLink>
        </div>
      </div>
      
    </div>
  );
}

export default withAuthenticationRequired(Landing, {
  onRedirecting: () => <div>Redirecting you to the login page</div>,
});