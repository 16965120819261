import React, { useState, useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import './News.css'

function News(props) {

    const [newsData, setNewsData] = useState()

    const formatDate = (date) => {
        return new Date(date).toLocaleString()
    }

    const formatNewsText = (newsText) => {
        return newsText.split("/newsupdate ")
    }

    useEffect(() => {
        /**
         * Retrieves news data asynchronously and updates the state with the reversed ordered data
         */
        const getNewsData = async () => {
            const news = await props.getNews()
            setNewsData(news.data.reverse())
        }
        getNewsData()
    }, []) 

    return (
        <div className="wrapper">
            <h1>SDM Internal News</h1>
            {newsData && (
                <>
                    {newsData.map(newsEntry => {
                        return(
                            <>
                                <p className="news-date">{formatDate(newsEntry.date)}</p>
                                <p className="news-text">{formatNewsText(newsEntry.msg)}</p>
                            </>
                        )
                    })}
                </>
            )}
        </div>
    );
}

export default withAuthenticationRequired(News, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});