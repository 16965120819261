import React, { useState, useEffect } from 'react';
import RawPrimetrustData from './RawPrimetrustData';
import { PrimetrustService } from '../../../api/PrimetrustService';
import { ClientService } from '../../../api/ClientService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import usePagination from '../../../hooks/usePagination'
import useAdamClients from '../../../hooks/useAdamClients';

function PrimeTrust({ accessToken }) {
    const [rawPrimetrust, setRawPrimetrust] = useState([])
    const [activeTab, setActiveTab] = useState(0)

    const [loading, setLoading] = useState(true)

    const primetrustService = new PrimetrustService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    const accountNumPerTab = [
        undefined,
        '420072427147',
        '420071200325'
    ]

    useEffect(() => {
        setLoading(true)

        primetrustService.getAllPrimetrust(paginationProps.pageSize, paginationProps.pageIndex, accountNumPerTab[activeTab]).then(rawPrimetrust => {
            setRawPrimetrust(rawPrimetrust.data.sort(compareByTimeDescending))
            paginationProps.setTotalItemCount(rawPrimetrust.totalItems)

            setLoading(false)
        })
        .catch(err =>{ 
            console.log(err)
            setLoading(false)
        });
    }, [paginationProps.pageIndex, paginationProps.pageSize, activeTab])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
        paginationProps.setPageIndex(0)
    }

    const updateRawPrimeTrustEntry = (transactionID, newData) => {

        // Use ID instead of index directly to handle filtered and/or paginated transaction lists
        const index = rawPrimetrust.findIndex(transaction => transaction.id === transactionID)

        setRawPrimetrust(oldRawPrimetrust => [
            ...oldRawPrimetrust.slice(0, index),
            newData,
            ...oldRawPrimetrust.slice(index + 1)
        ])
    }
    const compareByTimeDescending = (a, b) => {
        // Compare the date strings directly
        if (a.settled_on < b.settled_on) {
            return 1;
        } else if (a.settled_on > b.settled_on) {
            return -1;
        }
        // If both dates are the same, maintain the original order
        return 0;
    };
    return (
        // <div>
        //     <RawPrimetrustData
        //         rawPrimetrust={rawPrimetrust}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="198 (SDM Inc.)" />
                <Tab label="Secure Digital Markets Canada" />
            </Tabs>
            <div style={{width: '100%'}}>
                <RawPrimetrustData rawPrimetrust={rawPrimetrust} loading={loading || clientsLoading} clients={clients}
                    setPrimeTrustClient={(primeTrustID, clientCopperID) => primetrustService.setPrimetrustClient(primeTrustID, clientCopperID)} 
                    updateRawPrimeTrustEntry={updateRawPrimeTrustEntry} paginationProps={paginationProps} />
            </div>
        </Box>
    );
}

export default PrimeTrust;