import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function ClientReportBalanceTable({ balanceMap, label }) {
    const assets = Object.keys(balanceMap).sort((a, b) => a.localeCompare(b))
    const colSizeRatio = 1 / assets.length

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper sx={{ height: '100%' }}>
                    <Box display='flex' height='100%' justifyContent='center' alignItems='center'>
                        <b>{label}</b>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={10}>
                <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                {
                                    assets.map(asset => (<TableCell width={`${colSizeRatio}%`}>{asset}</TableCell>))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover>
                                {
                                    assets.map(asset => (<TableCell width={`${colSizeRatio}%`}>{balanceMap[asset]}</TableCell>))
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}