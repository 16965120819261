import React, { useState } from 'react';
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import './B2C2.css'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useDropzone} from 'react-dropzone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorAlerts from '../components/ErrorAlert';

const newCsvHeaders = [							
    {
        label: 'type',
        key: 'type'
    },
    {
        label: 'sub_type',
        key: 'sub_type'
    },
    {
        label: 'ref_data_exchange',
        key: 'ref_data_exchange'
    },
    {
        label: 'asset',
        key: 'asset'
    },
    {
        label: 'amount',
        key: 'amount'
    },
    {
        label: 'counter_asset_code',
        key: 'counter_asset_code'
    },
    {
        label: 'counter_asset_amount',
        key: 'counter_asset_amount'
    },
    {
        label: 'fee_asset_code',
        key: 'fee_asset_code'
    },{
        label: 'fee_asset_amount',
        key: 'fee_asset_amount'
    },
    {
        label: 'rebate_asset_code',
        key: 'rebate_asset_code'
    },
    {
        label: 'rebate_asset_amount',
        key: 'rebate_asset_amount'
    },
    {
        label: 'rate',
        key: 'rate'
    },
    {
        label: 'txn_complete_ts',
        key: 'txn_complete_ts'
    },
    {
        label: 'transaction_id',
        key: 'transaction_id'
    },
]
const SignatureBankRequiredHeaders = [
    'Post Date', 'Amount', 'Text'
]
function BankExport(props) {
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [sheetData, setSheetData] = useState([])
    const [formatted, setFormatted] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({})
    const [steps, setSteps] = useState(['Imported', 'Processed'])
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false)
    // HEADER VALIDATION
    const [isHeaderValid, setIsHeaderValid] = useState(true);
    const fileReader = new FileReader();
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        // accept: {
        //     'text/csv': [],
        //     'application/vnd.ms-excel': [],
        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        //     '.xlsm' : []
        // },
        onDrop: e => handleChange(e),
        getFilesFromEvent: e => handleChange(e),
        disabled: files.length > 0
    });
    const validatingImportedHeader = (importedHeader) => {
        const isValid = SignatureBankRequiredHeaders.every(element => {
            return importedHeader.includes(element);
        });
        return isValid;
    }
    const csvToJson = (csv) => {
        let array = csv.split("\n");
        console.log(array)
        // All the rows of the CSV will be
        // converted to JSON objects which
        // will be added to result in an array
        let result = [];
        
        // The array[0] contains all the
        // header columns so we store them
        // in headers array
        console.log(array[0])
        let headers = array[0].split(",")
        console.log(headers)
        if(!validatingImportedHeader(headers)) {
            setIsHeaderValid(false)
        }
        // Since headers are separated, we
        // need to traverse remaining n-1 rows.
        for (let i = 1; i < array.length; i++) {
            let obj = {}
            
            // Create an empty object to later add
            // values of the current row to it
            // Declare string str as current array
            // value to change the delimiter and
            // store the generated string in a new
            // string s
            let str = array[i]
            let s = ''
            
            // By Default, we get the comma separated
            // values of a cell in quotes " " so we
            // use flag to keep track of quotes and
            // split the string accordingly
            // If we encounter opening quote (")
            // then we keep commas as it is otherwise
            // we replace them with pipe |
            // We keep adding the characters we
            // traverse to a String s
            let flag = 0
            for (let ch of str) {
                if (ch === '"' && flag === 0) {
                flag = 1
                }
                else if (ch === '"' && flag == 1) flag = 0
                if (ch === ',' && flag === 0) ch = '|'
                if (ch !== '"') s += ch
            }
            
            // Split the string using pipe delimiter |
            // and store the values in a properties array
            let properties = s.split("|")
        
            // For each header, if the value contains
            // multiple comma separated data, then we
            // store it in the form of array otherwise
            // directly the value is stored
            for (let j in headers) {
                if (properties[j].includes(",")) {
                obj[headers[j]] = properties[j]
                    .split(",").map(item => item.trim())
                }
                else obj[headers[j]] = properties[j]
            }
            
            // Add the generated object to our
            // result array
            result.push(obj)
        }
        return result;
    }
    const formattingImported = (dataArray) => {
        let result = []
        for(let i = 0; i <dataArray.length; i++) {
            // console.log(typeof(dataArray[i].Text))
            // console.log(dataArray[i]['Post Date'])
            let curRow = {}
            curRow.type = Number(dataArray[i].Amount) > 0 ? 'Deposit' : 'Withdrawal';
            curRow.sub_type = ''
            curRow.ref_data_exchange = ''
            curRow.asset = 'USD'
            curRow.amount = Math.abs(dataArray[i].Amount)
            curRow.counter_asset_code = ''
            curRow.counter_asset_amount = ''
            curRow.fee_asset_code = ''
            curRow.fee_asset_amount = ''
            curRow.rebate_asset_code = ''
            curRow.rebate_asset_amount = ''
            curRow.rate = ''
            curRow.txn_complete_ts = new Date(dataArray[i]['Post Date']).toISOString()
            curRow.transaction_id = dataArray[i].Text.toString()
            curRow.order_id = ''
            curRow.from_address = ''
            curRow.to_address = ''
            curRow.contract_address = ''
            curRow.blockchain_transaction_id = ''
            curRow.blockchain_address = ''
            curRow.counterparty = ''
            curRow.notes = ''
            curRow.tags = ''
            result.push(curRow)
        }
        return result
    }
    const fileValidation = (filename) => {
        const allowedExtensions =
                    /(\.csv|\.xlsx|\.xlsm|\.xls)$/i;
        console.log(filename)
        console.log(allowedExtensions.exec(filename))
        if(!allowedExtensions.exec(filename)) {
            return false
        }
        else return true
    }
    const handleProcessingFile = (fileList) => {
        setLoading(true)
        fileReader.onload = function (event) {
            const csvOutput = event.target.result;
            const wb = XLSX.read(csvOutput, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            console.log(data);
            let result = csvToJson(data)
            console.log(result)
            // setSheetData(result)
            let formattedResult = formattingImported(result)
            console.log(formattedResult)
            setFormatted(formattedResult)
            setLoading(false)
        };
        fileReader.readAsBinaryString(fileList[0]);
    }
    const handleChange = async (e) => {
        console.log(e)
        const fileList = [...files]
        let importedFiles //= e.target.files
        if(!e.target) {
            importedFiles = await e[0].getFile()
            console.log(importedFiles)
            let isValid = fileValidation(importedFiles.name)
            if(isValid) {
                fileList.push(importedFiles);
            }
            else {
                alert('Invalid file type')
            }
        }
        else {
            importedFiles = e.target.files
            console.log(importedFiles)
            let isValid = fileValidation(importedFiles[0].name)
            if(isValid) {
                fileList.push(importedFiles[0]);
            }
            else {
                alert('Invalid file type')
            }
        }
        setFiles(fileList)
        handleProcessingFile(fileList)

    };
    const handleDeleteImported = (file, index) => {
        console.log(file)
        console.log(index)
        let filesArr = [...files]
        let fileIndex = filesArr.filter((f, id) => f === file && id === index)
        if(fileIndex[0]) {
            filesArr.splice(index, 1)
        }
        setFiles(filesArr)
        setFormatted([])
    }
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const totalSteps = () => {
        return steps.length;
    }
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    const displayProcessedContent = () => {
        setShowTable(true)
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    return (
        <div>
            BANK EXPORT
            {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
            {formatted.length > 0 ? <CSVLink data={formatted} headers={newCsvHeaders}>
                Download me
            </CSVLink> : null} */}
            <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep} style={{ marginBottom: 10}}>
                {steps.map((label, index) => (
                index === 1 ? formatted.length > 0 ?
                <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                        {label} {loading? <CircularProgress /> : null}
                    </StepButton>
                </Step>
                : <Step key={label} completed={completed[index]} disabled>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                    </StepButton>
                </Step>
                :<Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                </StepButton>
            </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
                ) : (
                <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                    {activeStep === 0 ? 
                        <div className='componentContainer'>
                            <section className="container">
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                <aside>
                                    {files.length> 0 ? files.map((file, index) => 
                                    <ul>
                                        {file.name}
                                        <IconButton aria-label="delete" size="small" onClick={() => handleDeleteImported(file, index)}><DeleteIcon /></IconButton>
                                    </ul>) : null}
                                </aside>
                            </section>
                        </div>
                        : <div className='processedContainer'>
                            <div className='buttonGroup'>
                                <Button variant="outlined" onClick={displayProcessedContent} startIcon={<FindInPageOutlinedIcon />}>
                                    View Exported File
                                </Button>
                                <Button variant="contained" endIcon={<DownloadOutlinedIcon />}>
                                    <CSVLink data={formatted} headers={newCsvHeaders} style={{textDecoration: 'none', color: 'white'}} filename={'SB_LUKKA.csv'}>
                                        Download
                                    </CSVLink>
                                </Button>
                            </div>
                            {showTable ? 
                            <TableContainer component={Paper} sx={{maxHeight: 500, marginTop: 4}}>
                                {loading ? <LinearProgress color="success"/> : null}
                                <Table sx={{ minWidth: 650}} aria-label="simple table" size="small">
                                    <TableHead>
                                    <TableRow>
                                        {newCsvHeaders.map(h => <TableCell>{h.label}</TableCell>)}
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {formatted.slice(0, 50).map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {newCsvHeaders.map(h =>
                                            h.label === 'transaction_id' ? 
                                            <Tooltip title={row[h.label]} placement="top"><TableCell>{typeof(row[h.label]) === 'string' ? row[h.label].substr(0, 20) + '...' : row[h.label][0]}</TableCell></Tooltip>
                                            :h.label === 'txn_complete_ts' ? 
                                            <Tooltip title={row[h.label]} placement="top"><TableCell>{row[h.label].substr(0, 10)}</TableCell></Tooltip>
                                            :<TableCell>{row[h.label]}</TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : null}
                        </div>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {activeStep === 1 ? <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button> : null}
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep === 0 ? <Button onClick={handleNext} sx={{ mr: 1 }} disabled={formatted.length === 0}>
                        Next
                    </Button> : null}

                    {/* <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext} sx={{ mr: 1 }} disabled={formatted.length === 0}>
                        Next
                    </Button> */}
                    {/* {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                        <Typography variant="caption" sx={{ display: 'inline-block' }}>
                            Step {activeStep + 1} already completed
                        </Typography>
                        ) : (
                        <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                            ? 'Finish'
                            : 'Complete Step'}
                        </Button>
                        ))} */}
                    </Box>
                </React.Fragment>
                )}
            </div>
            </Box>
            <ErrorAlerts 
                show={!isHeaderValid} 
                errorMessage={"Imported File has wrong format, make sure your imported file contains 'Post Date', 'Amount', 'Text' columns"}
                handleClose={()=> setIsHeaderValid(true)}
            />
        </div>
    );
}

export default BankExport;