import React, { useState, useEffect } from 'react';
import RawFireblocksData from './RawFireblocksData';
import { FireblocksService } from '../../../api/FireblocksService';
import { ClientService } from '../../../api/ClientService'
import CircularProgress from '@mui/material/CircularProgress';
import usePagination from '../../../hooks/usePagination'
import useAdamClients from '../../../hooks/useAdamClients'
import { Button } from '@mui/material';

function Fireblocks({accessToken}) {

    const [rawFireblocks, setRawFireblocks] = useState([])
    const [loading, setLoading] = useState(true)

    const fireblocksService = new FireblocksService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    const [directionlessDownloading, setDirectionlessDownloading] = useState(false)

    function downloadBalances() {
        setDirectionlessDownloading(true);

        const sheetType = "xlsx";

        fireblocksService.downloadFireblocksDirectionless(sheetType).then((blob) => {
            try {
                const blobURL = window.URL.createObjectURL(blob);
                const tmpLink = window.document.createElement("a");

                tmpLink.href = blobURL;
                tmpLink.download = `fbdirectionless.${sheetType}`;
                tmpLink.click();

                window.URL.revokeObjectURL(blobURL);
            } catch (err) {
                console.error(err);
            }

            setDirectionlessDownloading(false);
        });
    }

    /**
     * Updates the rawFireblocks array by replacing the entry with the specified transaction ID
     * with the new data.
     * @param {string} transactionID - The ID of the transaction to update.
     * @param {object} newData - The new data to replace the old entry with.
     * @returns None
     */
    const updateRawFireblocksEntry = (transactionID, newData) => {
        const index = rawFireblocks.findIndex(transaction => transaction.id === transactionID)

        setRawFireblocks(oldRawFireblocks => [
            ...oldRawFireblocks.slice(0, index),
            newData,
            ...oldRawFireblocks.slice(index + 1)
        ])
    }

    /**
     * Executes a side effect function that fetches data from the Fireblocks service and updates the component state.
     * @param {Function} setLoading - A function to set the loading state of the component.
     * @param {Function} setRawFireblocks - A function to set the raw Fireblocks data in the component state.
     * @param {Object} paginationProps - An object containing pagination properties like pageSize and pageIndex.
     */
    useEffect(() => {
        setLoading(true)

        fireblocksService.getAllFireblocks(paginationProps.pageSize, paginationProps.pageIndex).then(rawFireblocks => {
            setRawFireblocks(rawFireblocks.data)
            paginationProps.setTotalItemCount(rawFireblocks.totalItems)

            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [paginationProps.pageIndex, paginationProps.pageSize])
    return (
        <div>
            {loading || clientsLoading? 
            <CircularProgress/> 
            :
            <>
                <RawFireblocksData
                    rawFireblocks={rawFireblocks}
                    updateRawFireblocksEntry={updateRawFireblocksEntry}
                    clients={clients}
                    setFireblocksClient={(fireblocksID, clientCopperID) => fireblocksService.setFireblocksClient(fireblocksID, clientCopperID)}
                    paginationProps={paginationProps}
                />
                <br />
                <Button variant='contained' disabled={directionlessDownloading} onClick={() => downloadBalances()}>Download Directionless Transactions</Button>
            </>
            }
        </div>
    );
}

export default Fireblocks;