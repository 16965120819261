export const newCsvHeaders = [
    {
        label: 'type',
        key: 'type'
    },
    {
        label: 'ref_data_exchange',
        key: 'ref_data_exchange'
    },
    {
        label: 'base_asset_code',
        key: 'base_asset_code'
    },
    {
        label: 'base_asset_amount',
        key: 'base_asset_amount'
    },
    {
        label: 'fee_asset_code',
        key: 'fee_asset_code'
    },
    {
        label: 'fee_asset_amount',
        key: 'fee_asset_amount'
    },
    {
        label: 'transfer_start_ts',
        key: 'transfer_start_ts'
    },
    {
        label: 'transfer_complete_ts',
        key: 'transfer_complete_ts'
    },
    {
        label: 'blockchain_transaction_id',
        key: 'blockchain_transaction_id'
    },
    {
        label: 'blockchain_address',
        key: 'blockchain_address'
    },
    {
        label: 'transfer_id',
        key: 'transfer_id'
    },
    {
        label: 'from_address',
        key: 'from_address'
    },
    {
        label: 'to_address',
        key: 'to_address'
    },
    {
        label: 'contract_address',
        key: 'contract_address'
    },
    {
        label: 'counterparty',
        key: 'counterparty'
    },
    {
        label: 'notes',
        key: 'notes'
    },
    {
        label: 'tags',
        key: 'tags'
    },
]