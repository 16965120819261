import React, {useState, useEffect} from 'react';
import { BalancesService } from '../../../api/BalancesService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LPTXDialog from './LPTXDialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
            },
            '&:active': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity,
            ),
            },
        },
    },
}));

const LP_LIST = [
    'b2c2',
    'aquanow',
    'cumberland',
    'dvchain',
    'enigma',
    'falconx',
    'flowtraders',
    'galaxy',
    'mobilum',
    'nonco',
    'wintermute'
];

function LPBalances({token}) {
    let balanceService = new BalancesService(token)
    const [currentLP, setCurrentLP] = useState('b2c2')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const [curSelectedAsset, setCurSelectedAsset] = useState('');
    const [selectedTxs, setSelectedTxs] = useState([]);
    const [openTxPage, setOpenTxPage] = React.useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLPChange = (event, index) => {
        console.log(LP_LIST[index])
        setCurrentLP(LP_LIST[index])
        handleClose()
    }

    // useEffect(() => {
    //     console.log(currentLP);
    // },[currentLP])

    useEffect(() => {
        getFBBalances(currentLP)
    },[currentLP])
    const getFBBalances = async(provider) => {
        setLoading(true)
        let res = await balanceService.geLPBalances(provider)
        console.log(res)
        setLoading(false)
        setData(res)
    }
    const handleOpenTxPage = (transactions) => {
        console.log(transactions);
        setSelectedTxs(transactions)
        setOpenTxPage(true)
    }
    const handleCloseTxPage = () => {
        setSelectedTxs([])
        setOpenTxPage(false)
    }
    const Row = (rawDataRow, index) => {
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curSelectedAsset === rawDataRow.asset}>
                <TableCell align='left'>{rawDataRow.asset}</TableCell>
                <TableCell align='left'>{rawDataRow.balance}</TableCell>
                {/* <TableCell align='left'>{rawBcbDataRow.credit === "0" ? 'TRUE': 'FALSE'}</TableCell> */}
                <TableCell align='left' sx={{color: 'green'}}>{rawDataRow.in}</TableCell>
                <TableCell align='left' sx={{color: 'red'}}>{rawDataRow.out}</TableCell>
                {/* <TableCell align='left'>{moment(rawBcbDataRow.value_date).format('L')}</TableCell> */}
                <TableCell align='left'>
                    <Button variant="text" onClick={() => handleOpenTxPage(rawDataRow.transactions)}>View Transactions</Button>
                </TableCell>
            </TableRow>
        )
    }

    const dataTable = Object.keys(data).map((asset,index) => Row(data[asset], index))
    return (
        <div>
            <div style={{margin: 5}}>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Liquidity Provider {currentLP? ': ' + currentLP: null}
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {LP_LIST.map((c, index) => 
                        <MenuItem key={index} onClick={e => handleLPChange(e, index)} disableRipple selected={currentLP == c}>
                            {c}
                        </MenuItem>
                    )}
                </StyledMenu>
            </div>
            <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Asset</TableCell>
                            <TableCell>Total Balance</TableCell>
                            <TableCell>IN</TableCell>
                            <TableCell>OUT</TableCell>
                            <TableCell align="left"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTable}
                    </TableBody>
                </Table>
            </TableContainer>
            <LPTXDialog open={openTxPage} handleClose={handleCloseTxPage} Transition={Transition} transactions={selectedTxs}/>
        </div>
    );
}

export default LPBalances;