/**
 * FinclusiveService Service
 */
export class FinclusiveService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async getAllFinclusive(pageSize, pageNum){
        const response = await fetch(
            this.API_URL + `/finclusive?size=${pageSize}&page=${pageNum}`,
            {
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async setFinclusiveClient(finclusiveTxId, clientCopperID) {
        const response = await fetch(
            this.API_URL + '/finclusive/setclient',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.accessToken}`
                },
                body: JSON.stringify({
                    finclusiveTxId,
                    clientCopperID
                })
            }
        );

        return response.json();
    }
}