import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import logo from "../assets/logo-footer.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuIcon from "@mui/icons-material/Menu";

import "./Header.css";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
const { Title } = Typography;

function Header(props) {
  console.log(props.isAdamUser);
  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } =
    useAuth0();

  const baseButtonStyle = {
    height: "80%",
    color: "black",
    backgroundColor: "#ffcc36",
    border: "none",
    "&:hover": { color: "black", border: "none", backgroundColor: "#ffcc36" },
  };

  const headerButtonStyle = {
    ...baseButtonStyle,
    "@media (min-width: 800px)": {
      marginLeft: 'auto',
      marginRight: 5,
      justifySelf: "center",
      alignSelf: "center",
    },
  };
  

  const [modalVisible, setModalVisible] = useState(false);

  const { roles, isTalosUser } = props;

  // const login = async () => oktaAuth.signInWithRedirect();
  // const logout = async () => oktaAuth.signOut();

  const handleClose = () => {
    setModalVisible(false);
  };

  const [userId, setUserId] = useState();

  useEffect(() => {
    if (user) {
      setUserId(user.sub);
    }
  }, [user]);
  // console.log('Header::isAuthenticated:', isAuthenticated);
  return (
    <div className="header">
      <NavLink className="headerMode" to="/">
        {/* <h2>SDM HUB</h2> */}
        <img src={logo} alt="sdm logo"></img>
      </NavLink>

      {isAuthenticated && (
        <>
          <NavLink
            className="headerItem"
            to="/account"
            style={({ isActive }) => {
              return {
                color: isActive ? "#ffef96" : "",
              };
            }}
          >
            API Keys
          </NavLink>

          {roles &&
            roles.length >= 1 &&
            roles &&
            !roles.includes("CustomerUser") && (
              <NavLink
                className="headerItem"
                to="/dashboard"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ffef96" : "",
                  };
                }}
              >
                Lukka
              </NavLink>
            )}

          {/* TODO: conditionally change the href on this */}

          {roles.includes("CustomerUser") && (
            <>
              {isTalosUser ? (
                <a className="headerItem" href="http://trade.sdm.co/">
                  SDMLive 
                </a>
              ) : (
                <a className="headerItem" onClick={() => setModalVisible(true)}>
                  SDM Live 
                </a>
              )}
            </>
          )}

          {props.roles.includes("Developer") && (
            <NavLink
              className="headerItem"
              to="/dev-dashboard"
              style={({ isActive }) => {
                return {
                  color: isActive ? "#ffef96" : "",
                };
              }}
            >
              Dev
            </NavLink>
          )}
          {/*<NavLink //'News' tab for ADAM dashboard 
                        className="headerItem" to="/news"
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "#ffef96" : "",
                            }
                        }}
                    >
                        News
                    </NavLink>*/}

          {props.isAdamUser && (
            <NavLink
              className="headerItem"
              to="/adam-dashboard"
              style={({ isActive }) => {
                return {
                  color: isActive ? "#ffef96" : "",
                };
              }}
            >
              ADAM
            </NavLink>
          )}
          {/* <NavLink
                        className="headerItem" to="/news"
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "#ffef96" : "",
                            }
                        }}
                    >
                        News
                    </NavLink> */}
        </>
      )}


            <div className="logout-button">
            {isAuthenticated ? (
        <Button
          variant="outlined"
          startIcon={<LogoutIcon />}
          onClick={() => logout({ returnTo: window.location.origin })}
          sx={headerButtonStyle}
        >
          Log Out
        </Button>
      ) : (
        <Button
          variant="contained"
          startIcon={<LoginIcon />}
          onClick={loginWithRedirect}
          sx={headerButtonStyle}
        >
          Log In
        </Button>
      )}
            </div>
         
            {isAuthenticated ? (
        <Button
        className="logout-button"
          variant="outlined"
          startIcon={<LogoutIcon />}
          onClick={() => logout({ returnTo: window.location.origin })}
          sx={headerButtonStyle}
        >
          Log Out
        </Button>
      ) : (
        <Button
          variant="contained"
          startIcon={<LoginIcon />}
          onClick={loginWithRedirect}
          sx={headerButtonStyle}
        >
          Log In
        </Button>
      )}

      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Please contact your SDM Account Manager to configure your trading access"
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            support@securedigitalmarkets.com
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            sx={baseButtonStyle}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Header;
