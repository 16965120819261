import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { Select, MenuItem } from '@mui/material';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import { Autocomplete, TextField } from '@mui/material';
import SimpleTablePagination from '../../../components/SimpleTablePagination';
import ClientAutocompleteSelector from '../../../components/clientAutocompleteSelector';

function RawOpenpayData({rawData, loading, clients, update, setUpdate, setOpenpaydClient, updateRawDataEntry, paginationProps}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const [filterValue, setFilterValue] = useState('All');
    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
    };
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row.data)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }
    const clientOptions = clients.map(client => ({
        id: client.clientCopperId,
        label: client.clientPrimaryName
    }))
    const getOpenpaydClientName = openpaydRawData => {
        const [ openpaydClient ] = openpaydRawData.adamClients
        return openpaydClient ? openpaydClient.clientPrimaryName : null
    }
    const updateClientSide = (option, RawData) => {
        const newData = { ...RawData }

        // Get client associated with selected option (should be list of 1 element)
        const fortressClient = clients.filter(client => client.clientCopperId === option?.id)

        newData.adamClients = fortressClient
        updateRawDataEntry(newData.id, newData)
    }
    const handleClientChange = async (option, RawData) => {
        let counter = update + 1
        await setOpenpaydClient(RawData.id, option?.id)
        .then(res => {
            if(res) {
                updateClientSide(option, RawData)
                setUpdate(counter)
            }
            else {
                console.error('Something went wrong while updating Openpayd transaction')
            }
        })
    }
    const RawCFDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                {/* <TableCell>{rawDataRow.short_id}</TableCell> */}
                {/* <TableCell align='left'>{rawBcbDataRow.network}</TableCell> */}
                
                {/* <TableCell align='left'>{rawBcbDataRow.details_reference}</TableCell> */}
                {/* <TableCell>{rawDataRow.type}</TableCell>
                <TableCell>{rawDataRow.payment_type}</TableCell> */}
                <TableCell>{rawDataRow.source_info["type"]}</TableCell>
                {/* <TableCell>{rawDataRow.destination_info["type"]}</TableCell> */}
                <TableCell>{rawDataRow.source}</TableCell>
                {/* <TableCell>{rawDataRow.destination}</TableCell> */}
                <TableCell>{rawDataRow.total_amount["value"]}</TableCell>
                <TableCell>{rawDataRow.fee["value"]}</TableCell>
                <TableCell>{rawDataRow.total_amount["currency"]}</TableCell>
                <TableCell>{rawDataRow.comment}</TableCell>
                <TableCell>{moment(rawDataRow.updated_date).format('L')}</TableCell>
                <TableCell align='left'>
                    <ClientAutocompleteSelector
                        clients={clients}
                        value={getOpenpaydClientName(rawDataRow)}
                        onChange={(e, v) => handleClientChange(v, rawDataRow)}
                        onClear={() => handleClientChange(null, rawDataRow)}
                    />
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const openpaydTable = rawData.filter(tx => filterValue == 'All' ? tx : tx.total_amount["currency"] == filterValue).map((rawDataRow,index) => RawCFDataRow(rawDataRow,index))

    if (rawData.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }} >
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table" style={{ width: "100%", tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Id</TableCell> */}
                                {/* <TableCell>Type</TableCell>
                                <TableCell>Payment</TableCell> */}
                                <TableCell>Source Type</TableCell>
                                {/* <TableCell>Destination Type</TableCell> */}
                                <TableCell>Source</TableCell>
                                {/* <TableCell>Destination</TableCell> */}
                                <TableCell>Amount</TableCell>
                                <TableCell>Fee</TableCell>
                                <TableCell>
                                    Currency
                                    {/* <Select variant='standard' value={filterValue} onChange={handleFilterChange} id="demo-simple-select-standard">
                                        <MenuItem value="All">All Currency</MenuItem>
                                        <MenuItem value="USD">USD</MenuItem>
                                        <MenuItem value="CAD">CAD</MenuItem>
                                        <MenuItem value="EUR">EUR</MenuItem>
                                        <MenuItem value="GBP">GBP</MenuItem>
                                    </Select> */}
                                </TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {openpaydTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Openpayd Transaction Details"}
            />
        </div>
    );
}

export default RawOpenpayData;