import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import JSONPretty from 'react-json-pretty';

import DialogTitle from '@mui/material/DialogTitle';
import './TransactionInfoModal.css'
export default function TransactionInfoModal({openDialog, closeDialog, data, title }) {
    const JsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'darkorange' }
    }
    return (
        <Dialog
            open={openDialog}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent className='transactionDetailsContainer'>
            <JSONPretty id="json-pretty" data={data}></JSONPretty>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>close</Button>
            </DialogActions>
        </Dialog>
    );
}