import React, { useState } from 'react';
import './B2C2.css'
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import  {useDropzone} from 'react-dropzone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ErrorAlerts from '../components/ErrorAlert';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment'
import { instance } from '../api/authService'
import {newCsvHeaders} from './utils/constants'
const BCB_ACCOUNT_ID = {
    'CAD-Enterprise' : 4139,
    'EUR-Enterprise' : 4135,
    'GBP-Enterprise' : 4131,
    'CAD-BLINC' : 16526,
    'EUR-BLINC' : 6238,
    'GBP-BLINC' : 6239
}
const BCBrequiredHeader = [
    "Transaction ID", "Timestamp", "Amount"
]
const Assets = ['CAD', 'GBP', 'EUR']
const Type = ['Enterprise', 'BLINC']
function BCB() {
    const [exportType, setExportType] = useState('api');
    const [apiStartDate, setApiStartDate] = useState(moment());
    const [apiEndDate, setApiEndDate] = useState(moment());
    const [files, setFiles] = useState([]);
    const [formatted, setFormatted] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({})
    const [steps, setSteps] = useState(['Imported', 'Processed'])
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false)
    const [selectedAsset, setSelectedAsset] = useState('CAD');
    const [selectedType, setSelectedType] = useState('Enterprise')
    const [apiData, setApiData] = useState([]);
     // HEADER VALIDATION
    const [isHeaderValid, setIsHeaderValid] = useState(true);
    // EXPORT TYPE
    const handleChangeExportType = (event) => {
        setExportType(event.target.value);
    };
    const handleRetrieveData = async() => {
        setLoading(true)
        setApiData([])
        let fromDate = (apiStartDate._d).toISOString().substring(0, 10)
        let toDate = (apiEndDate._d).toISOString().substring(0, 10)
        console.log(selectedAsset)
        console.log(selectedType)

        let account_id = BCB_ACCOUNT_ID[selectedAsset+'-'+selectedType]
        console.log(account_id)
        let result = await instance.get('/getBCBTransferTx?dateFrom=' + fromDate + '&dateTo=' + toDate + '&id=' + account_id)
        console.log(result)
        if(result){
            setLoading(false)
            result.status === 200 && setApiData(result.data)
        }
    }
    const handleAssetChange = (event) => {
        setSelectedAsset(event.target.value);
    };
    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    }
    //const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
    const fileReader = new FileReader();
    // new drop zone functions
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        // accept: {
        //     'text/csv': [],
        //     'application/vnd.ms-excel': [],
        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        //     '.xlsm' : []
        // },
        onDrop: e => handleChange(e),
        getFilesFromEvent: e => handleChange(e),
        disabled: files.length > 0
    });
    const validatingImportedHeader = (importedHeader) => {
        const isValid = BCBrequiredHeader.every(element => {
            return importedHeader.includes(element);
        });
        return isValid;
    }
    const csvToJson = (csv) => {
        let array = csv.split("\n");
        let result = [];
        console.log(array[0])
        let headers = array[0].split(",").map(header => header.replace(/['"]+/g, ''))
        console.log(headers)
        if(!validatingImportedHeader(headers)) {
            setIsHeaderValid(false)
        }
        for (let i = 1; i < array.length; i++) {
            console.log('hello')
            let obj = {}
            let str = array[i]
            let s = ''
            let flag = 0
            for (let ch of str) {
                if (ch === '"' && flag === 0) {
                flag = 1
                }
                else if (ch === '"' && flag == 1) flag = 0
                if (ch === ',' && flag === 0) ch = '|'
                if (ch !== '"') s += ch
            }
            let properties = s.split("|")
            for (let j in headers) {
                if (properties[j].includes(",")) {
                obj[headers[j]] = properties[j]
                    .split(",").map(item => item.trim())
                }
                else obj[headers[j]] = properties[j]
            }
            console.log(obj)
            result.push(obj)
        }
        return result;
    }
    // function toISOLocal(d) {
    //     let z  = n =>  ('0' + n).slice(-2);
    //     return d.getFullYear() + '-'
    //             + z(d.getMonth()+1) + '-' +
    //             z(d.getDate()) +
    //             ' '+
    //             z(d.getHours()) + ':'  + 
    //             z(d.getMinutes()) + ':' +
    //             z(d.getSeconds())
    // }
    const formattingImported = (dataArray) => {
        let result = []
        for(let i = 0; i <dataArray.length; i++) {
            let curRow = {}
            curRow.type = Number(dataArray[i].Amount) > 0 ? 'Buy' : 'Sell';;
            curRow.ref_data_exchange = ''
            curRow.base_asset_code = selectedAsset
            curRow.base_asset_amount = Math.abs(dataArray[i].Amount)
            curRow.fee_asset_code = ''
            curRow.fee_asset_amount = ''
            curRow.transfer_start_ts = ''
            curRow.txn_complete_ts = new Date(dataArray[i].Timestamp).toISOString()
            curRow.blockchain_transaction_id = ''
            curRow.blockchain_address = ''
            curRow.transfer_id = dataArray[i]['Transaction ID']
            curRow.from_address = ''
            curRow.to_address = ''
            curRow.contract_address = ''
            curRow.counterparty = ''
            curRow.notes = ''
            curRow.tags = ''
            result.push(curRow)
        }
        return result
    }
    const fileValidation = (filename) => {
        const allowedExtensions =
                    /(\.csv|\.xlsx|\.xlsm|\.xls)$/i;
        if(!allowedExtensions.exec(filename)) {
            return false
        }
        else return true
    }
    const handleChange = async(e) => {
        console.log(e)
        const fileList = [...files]
        let importedFiles //= e.target.files
        if(!e.target) {
            importedFiles = await e[0].getFile()
            console.log(importedFiles)
            let isValid = fileValidation(importedFiles.name)
            if(isValid) {
                fileList.push(importedFiles);
            }
            else {
                alert('Invalid file type')
            }
        }
        else {
            importedFiles = e.target.files
            console.log(importedFiles)
            let isValid = fileValidation(importedFiles[0].name)
            if(isValid) {
                fileList.push(importedFiles[0]);
            }
            else {
                alert('Invalid file type')
            }
        }
        setFiles(fileList)
        handleProcessingFile(fileList)
    };
    const displayProcessedContent = () => {
        setShowTable(true)
    }
    const handleProcessingFile = (fileList) => {
        setLoading(true)
        fileReader.onload = function (event) {
            const csvOutput = event.target.result;
            const wb = XLSX.read(csvOutput, { type: "binary" });
            // TODO: might need to change the sheet number later
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            console.log(data);
            let result = csvToJson(data)
            console.log(result)
            // setSheetData(result)
            let formattedResult = formattingImported(result)
            console.log(formattedResult)
            setFormatted(formattedResult)
            setLoading(false)
        };
        fileReader.readAsBinaryString(fileList[0]);
    }
    const totalSteps = () => {
        return steps.length;
    }
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    
    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };
    
    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    const handleDeleteImported = (file, index) => {
        console.log(file)
        console.log(index)
        let filesArr = [...files]
        let fileIndex = filesArr.filter((f, id) => f === file && id === index)
        if(fileIndex[0]) {
            filesArr.splice(index, 1)
        }
        setFiles(filesArr)
        setFormatted([])
    }
    // useEffect(() => {
    //     console.log(formatted.length)
    // }, [formatted])
    // console.log(formatted)
    return (
        <div>
            BCB
            <div>
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Export From: </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={exportType}
                        onChange={handleChangeExportType}
                    >
                        <FormControlLabel value="api" control={<Radio />} label="API" />
                        <FormControlLabel value="upload" control={<Radio />} label="File Upload" />
                    </RadioGroup>
                </FormControl>
            </div>
            {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
            {formatted.length > 0 ? <CSVLink data={formatted} headers={newCsvHeaders}>
                Download me
            </CSVLink> : null} */}
            {exportType === 'api' ? 
            <>
                <div>
                    <FormControl style={{ flexDirection: 'row', alignItems: 'center'}}>
                        <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>Asset Type: </FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={selectedAsset}
                                onChange={handleAssetChange}
                                row                    
                            >
                                {Assets.map((value, index) => 
                                    <FormControlLabel 
                                        key={index}
                                        value={value} 
                                        control={<Radio color="secondary"/>} 
                                        label={value} 
                                    />
                                )}
                            </RadioGroup>                
                        </FormControl>
                        <FormControl style={{ flexDirection: 'row', alignItems: 'center'}}>
                            <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>Bank Type: </FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={selectedType}
                                onChange={handleTypeChange}
                                row                    
                            >                
                                {Type.map((value, index) => <FormControlLabel key={index} value={value} control={<Radio color="success" />} label={value} />)}
                            </RadioGroup>                
                        </FormControl>            
                </div>
                <div style={{ marginTop: '10px'}}>
                    <FormControl style={{ flexDirection: 'row', alignItems: 'center', margin: '5px'}}>
                        <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>Start Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="From"
                                value={apiStartDate}
                                onChange={(newValue) => {
                                    setApiStartDate(newValue);
                                }}
                                maxDate={apiEndDate ? apiEndDate : null}
                                renderInput={(params) => <TextField {...params} />}    
                            />    
                        </LocalizationProvider>            
                    </FormControl>   
                    <FormControl style={{ flexDirection: 'row', alignItems: 'center', margin: '5px'}}>
                        <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>End Date: </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="To"
                                value={apiEndDate}
                                onChange={(newValue) => {
                                    setApiEndDate(newValue);
                                }}
                                minDate={apiStartDate ? apiStartDate : null}
                                maxDate={moment()}
                                renderInput={(params) => <TextField {...params} />}    
                            />
                        </LocalizationProvider>            
                    </FormControl>  
                </div>
                <div style={{margin: 'auto', marginTop: '10px', marginBottom: '10px'}}>
                    {loading ? 
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">Process</LoadingButton>
                    :<Button variant="outlined" onClick={() => handleRetrieveData()}>Process</Button>}
                </div>
                <div style={{margin: 'auto', marginTop: '5px'}}>
                    {apiData.length > 0 ? 
                    <Button variant="contained" endIcon={<DownloadOutlinedIcon />}>
                        <CSVLink 
                            data={apiData} 
                            headers={newCsvHeaders} 
                            style={{textDecoration: 'none', color: 'white'}} 
                            filename={'BCB_LUKKA_' +selectedType + '_' + selectedAsset +'.csv'}
                        >
                            Download CSV
                        </CSVLink>
                    </Button>
                    : null}
                </div>
            </>
            :<Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep} style={{ marginBottom: 10}}>
                    {steps.map((label, index) => (
                    index === 1 ? formatted.length > 0 ?
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {label} {loading? <CircularProgress /> : null}
                        </StepButton>
                    </Step>
                    : <Step key={label} completed={completed[index]} disabled>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                        </StepButton>
                    </Step>
                    :<Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                    </StepButton>
                    </Step>
                    ))}
                </Stepper>
                <div>
                    {allStepsCompleted() ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                    ) : (
                    <React.Fragment>
                        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                        {activeStep === 0 ? 
                            <div className='componentContainer'>
                                <section className="container">
                                    <FormControl style={{ flexDirection: 'row', alignItems: 'center'}}>
                                        <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>Asset Type: </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={selectedAsset}
                                            onChange={handleAssetChange}
                                            row
                                        >
                                            {Assets.map(value => <FormControlLabel value={value} control={<Radio />} label={value} />)}
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl style={{ flexDirection: 'row', alignItems: 'center'}}>
                                        <FormLabel id="demo-controlled-radio-buttons-group" style={{marginRight: 10}}>Bank Type: </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            row
                                        >
                                            {Type.map(value => <FormControlLabel value={value} control={<Radio />} label={value} />)}
                                        </RadioGroup>
                                    </FormControl>
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <aside>
                                        {files.length> 0 ? files.map((file, index) => 
                                        <ul>
                                            {file.name}
                                            <IconButton aria-label="delete" size="small" onClick={() => handleDeleteImported(file, index)}><DeleteIcon /></IconButton>
                                        </ul>) : null}
                                    </aside>
                                </section>
                            </div>
                            : <div className='processedContainer'>
                                <div className='buttonGroup'>
                                    <Button variant="outlined" onClick={displayProcessedContent} startIcon={<FindInPageOutlinedIcon />}>
                                        View Exported File
                                    </Button>
                                    <Button variant="contained" endIcon={<DownloadOutlinedIcon />}>
                                        <CSVLink 
                                            data={formatted} 
                                            headers={newCsvHeaders} 
                                            style={{textDecoration: 'none', color: 'white'}} 
                                            filename={'BCB_LUKKA' + '_' +selectedType + '_' + selectedAsset +'.csv'}
                                        >
                                            Download
                                        </CSVLink>
                                    </Button>
                                </div>
                                {showTable ? 
                                    <TableContainer component={Paper} sx={{maxHeight: 500, marginTop: 4}}>
                                        {loading ? <LinearProgress color="success"/> : null}
                                        <Table sx={{ minWidth: 650}} aria-label="simple table" size="small">
                                            <TableHead>
                                            <TableRow>
                                                {newCsvHeaders.map(h => <TableCell>{h.label}</TableCell>)}
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {formatted.slice(0, 50).map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {newCsvHeaders.map(h => 
                                                    h.label === 'transaction_id' ? 
                                                    <Tooltip title={row[h.label]} placement="top"><TableCell>{row[h.label].substr(0, 7) + '...' + row[h.label].substr(row[h.label].length-7, row[h.label].length)}</TableCell></Tooltip>
                                                    :h.label === 'txn_complete_ts' ? 
                                                    <Tooltip title={row[h.label]} placement="top"><TableCell>{row[h.label].substr(0, 10)}</TableCell></Tooltip>
                                                    :<TableCell>{row[h.label]}</TableCell>)}
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> : null}
                            </div>
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        {activeStep === 1 ? <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button> : null}
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep === 0 ? <Button onClick={handleNext} sx={{ mr: 1 }} disabled={formatted.length === 0}>
                            Next
                        </Button> : null}
                        {/* {activeStep !== steps.length &&
                            (completed[activeStep] ? (
                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                Step {activeStep + 1} already completed
                            </Typography>
                            ) : (
                            <Button onClick={handleComplete}>
                                {completedSteps() === totalSteps() - 1
                                ? 'Finish'
                                : 'Complete Step'}
                            </Button>
                            ))} */}
                        </Box>
                    </React.Fragment>
                    )}
                </div>
            </Box>}
                <ErrorAlerts 
                    show={!isHeaderValid} 
                    errorMessage={"Imported File has wrong format, make sure your imported file contains 'Transaction ID', 'Amount', 'Timestamp' columns"}
                    handleClose={()=> setIsHeaderValid(true)}
                />
        </div>
    );
}

export default BCB;