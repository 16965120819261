import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import './Developer.css'
import Box from '@mui/material/Box';

function Developer(props) {

    return (
        <div className="wrapper">
            Developer page
        </div>
    );

}

export default withAuthenticationRequired(Developer, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});