import React, { useState, useEffect } from 'react';
import RawFinclusiveData from './RawFinclusiveData';
import { FinclusiveService } from '../../../api/FinclusiveService';
import { ClientService } from '../../../api/ClientService';
import usePagination from '../../../hooks/usePagination';
import useAdamClients from '../../../hooks/useAdamClients';

function Finclusive({ accessToken }) {

    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(0);

    const finclusiveService = new FinclusiveService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    useEffect(() => {
        setLoading(true)

        finclusiveService.getAllFinclusive(paginationProps.pageSize, paginationProps.pageIndex).then(rawCF => {
            setRawData(rawCF.data.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)))
            paginationProps.setTotalItemCount(rawCF.totalItems)

            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
            
        });
    }, [paginationProps.pageIndex, paginationProps.pageSize]);

    const updateRawDataEntry = (transactionID, newData) => {
        const index = rawData.findIndex(transaction => transaction.id === transactionID)

        setRawData(oldRawData => [
            ...oldRawData.slice(0, index),
            newData,
            ...oldRawData.slice(index + 1)
        ])
    }

    return (
        <div>
            <RawFinclusiveData
                rawData={rawData}
                loading={loading || clientsLoading}
                clients={clients}
                update={update}
                setUpdate={setUpdate}
                setFinclusiveClient={
                    (finclusiveTxId, clientCopperID) => finclusiveService.setFinclusiveClient(
                        finclusiveTxId, clientCopperID
                )}
                updateRawDataEntry={updateRawDataEntry}
                paginationProps={paginationProps}
            />
        </div>
    );
}

export default Finclusive;