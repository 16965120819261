import * as React from 'react';
import './App.css';
import {
  BrowserRouter as Router, Routes, Route, useNavigate, Navigate, useSearchParams, useLocation
} from 'react-router-dom';
import PermissionError from './components/PermissionError';
//import { useShell } from 'react-ssh2-hook';
import Lukka from './pages/Lukka';
import Account from './pages/Account';
import Adam from './pages/adam';
import TradeTicketGenerator from './pages/adam/TradeTicketGenerator';
import ClientDetails from './pages/adam/ClientDetails';
import Landing from './pages/Landing';
import Developer from './pages/Developer';
import Trader from './pages/Trader';
import News from './pages/News';
// import TabPanel from './pages/adam';
import Header from './components/Header';
import Error from './pages/Error'
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios'
import { react } from '@babel/types';
import { getUserRoles, isCustomerUser, getNews } from './api/authService';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const AUDIENCE = process.env.REACT_APP_AUDIENCE;

function App() {
  // to check the current route/location and conditionally display the Header based on that
  const location = useLocation();

  const navigate = useNavigate();

  const {
    user,
    isAuthenticated,
    // loginWithRedirect, // unused and no effect
    getAccessTokenSilently,
  } = useAuth0();

  const [userId, setUserId] = React.useState()
  const [roles, setRoles] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [searchParams] = useSearchParams()
  const [token, setToken] = React.useState()
  const [isTalosUser, setIsTalosUser] = React.useState()
  const [isAdamUser, setIsAdamUser] = React.useState(false)

  React.useEffect(() => {
     // redirect to talos if user is customer user
    const redirect = searchParams.get('redirect')
    if (redirect === 'true'){
      setLoading(false)
    }
  }, [roles])

  React.useEffect(() => {
    if (user) {
      setUserId(user.sub)
      getToken()
    }
  }, [user])

  React.useEffect(() => {
    /**
     * Retrieves user roles data asynchronously and updates the state accordingly. Anc check if current user is adamUser
     */
    const getData = async () =>  {
      if (userId){
        setLoading(true)
        const response = await getUserRoles(userId)
        // console.log(response);
        if(response.result.filter(r => r.name == 'AdamUser').length > 0){
          setIsAdamUser(true)
        }
        setLoading(false)
        setUserRoles(response)
      }
    }
    getData()
  }, [userId]) 

  React.useEffect(() => {
     // Retrieves the list of customer users and checks if the current user is a Talos user.
    const getUsers = async () => {
      const isCustomerUserCheck = await isCustomerUser(user.email);
      // console.log('getUsers isCustomerUserCheck', isCustomerUserCheck);
      setIsTalosUser(isCustomerUserCheck);
    }
    if(user){
      getUsers()
    }
  }, [user])

  /**
   * Retrieves an access token asynchronously using the getAccessTokenSilently function.
   */
  const getToken = async () => {
    // Wrap the getAccessTokenSilently function call in a try-catch block
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          // `detailedResponse` adds data to token response, in which case token for Bearer shows up in token.access_token
          // detailedResponse: true,
          audience: AUDIENCE,
          issuerBaseURL: 'https://login.sdm.co/',
        }
      });
      setToken(token);
    } catch (error) {
      console.error("Failed to get access token:", error);
    }
  };

  /**
   * Updates the user roles state based on the response from the server.
   */
  const setUserRoles = (response) => {
    if (response.result) {
      let updatedRoles = [...roles] 
      response.result.forEach(role => {
        const newRole = role.name
        if (!roles.includes(newRole)) {
          updatedRoles.push(newRole)
        }
      })
      setRoles(updatedRoles)
    }
  }

  return (
    <div className="App">
    {!loading ? 
      roles.length === 0 && user ?
      <div style={{height: '100vh', width: '100vw', backgroundColor: 'black',display: 'flex', margin: 'auto', justifyContent: 'center', alignItems: 'center', }}>
        <PermissionError />
      </div>
      :<>
        {user && location.pathname !== "/justtradeticketgenerator" && (
          <Header roles={roles} isTalosUser={isTalosUser} isAdamUser={isAdamUser}/>
        )}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path='/account' element={<Account user={user} token={token} roles={roles} isTalosUser={isTalosUser} />} />
          <Route path='/dashboard' element={<Lukka />} />
          <Route 
            path='/dev-dashboard' 
            element={roles.includes('Developer')? <Developer/>: <div className='heading'>Must be a developer to access this page</div>}
          />
          <Route 
            path='/adam-dashboard'
            //element={roles.includes('AdamUser')? <Adam curUser={user} token={token}/> : <div className='heading'>Must be a adam user to access this page</div>} 
            element={ <Adam curUser={user} token={token}/>} 
          />
          {/* <Route 
            path='/adam-dashboard-new'
            element={<TabPanel />} 
          /> */}
          {/*<Route path='/news' element={<News getNews={getNews}/>}/>*/}
          <Route path="/clientDetails/:clientCopperId" element={<ClientDetails token={token} />} />
          <Route path="/justtradeticketgenerator" element={<TradeTicketGenerator curUser={user} token={token}/>} />
          <Route path='/error' element={<Error/>}/>
        </Routes>
      </>
    :
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
      }
    </div>
  );
}

export default App;
