import { Tooltip, tooltipClasses, styled, Button } from '@mui/material'

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
}))

export default function SimpleConfirmationTooltip({ confirmMessage, open, onClose, onConfirm, children, ...props }) {
    const handleConfirm = () => {
        onClose()
        onConfirm()
    }

    return (
        <HtmlTooltip
            {...props}
            open={open}
            title={
                <>
                    <div style={{fontSize: '14px'}}>{confirmMessage}</div>
                    <Button sx={{m: 1}} color='info' size="small" variant="outlined" onClick={() => onClose()}>Cancel</Button>
                    <Button sx={{m: 1}} size="small" variant="contained" color='error' onClick={() => handleConfirm()}>OK</Button>
                </>
            }
        >
            {children}
        </HtmlTooltip>
    )
}