import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinearProgress from '@mui/material/LinearProgress';
function CustomerBalances({data, loading}) {
    return (
        <div>
            <TableContainer component={Paper}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table aria-label="collapsible table">
                    <TableHead>
                    <TableRow>
                        {/* <TableCell /> */}
                        <TableCell>Asset</TableCell>
                        <TableCell align="left">Balance</TableCell>
                        {/* <TableCell align="left">Updated At</TableCell> */}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data && Object.keys(data).map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row}
                        </TableCell>
                        <TableCell align="left">{data[row]}</TableCell>
                        {/* <TableCell align="left">{data.lpBalance[row] ? data.lpBalance[row].balance.toLocaleString(): 0}</TableCell>
                        <TableCell align="left">
                            {data.fireblocksBal[row] ? data.lpBalance[row] ? (data.fireblocksBal[row].available + data.lpBalance[row].balance).toLocaleString() : data.fireblocksBal[row].available.toLocaleString() : data.lpBalance[row].balance.toLocaleString()}
                        </TableCell> */}
                        {/* <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amount : row.in.amount}</TableCell>
                        <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amountUSD : row.in.amountUSD}</TableCell>
                        <TableCell align="left">{row.date}</TableCell> */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default CustomerBalances;