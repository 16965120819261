import React, { useState, useEffect } from "react";
import { ClientService } from "../../../api/ClientService";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import "./emailmodal.css";

const EditEmailModal = ({ accessToken, clientCopperID, adamClient, onClose }) => {
  const [show, setShow] = useState(false);
  const [emails, setEmails] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  const [addEmail, setNewEmail] = useState("");

  const clientService = new ClientService(accessToken);

  const handleShow = async () => {
    console.log("Button clicked for clientCopperID:", clientCopperID);
    setShow(true);

    try {
      const fetchedEmails = await clientService.getAdamClientEmails(clientCopperID);
      console.log(fetchedEmails);
      setEmails(fetchedEmails);

      // Fetch notification types for each email and set the initial checkbox state
      const initialCheckedState = {};
      for (const email of fetchedEmails) {
        const notificationTypes = await clientService.getNotificationTypesForEmail(email.id);
        console.log(email.id);
        // Set the initial checkbox state based on the notification types
        initialCheckedState[email.id] = {
          tradeConfirmation: notificationTypes.includes("tradeConfirmation"),
          talos: notificationTypes.includes("talos"),
          monthlyTradeReport: notificationTypes.includes("monthlyTradeReport"),
        };
      }
      setCheckedState(initialCheckedState);
    } catch (error) {
      console.error("Error fetching emails:", error);
      // Handle the error accordingly
    }
  };

  const handleClose = () => {
    // setShow(false);
    if (onClose) {
        onClose(); // call the callback function
    }
};
const handleSubmit = async () => {
  const trimmedEmail = addEmail.trim(); // Trim the email input

  if (!trimmedEmail) {
    alert("Please enter an email address."); // Replace with a more sophisticated validation/error message
    return;
  }

  try {
    const newEmail = await clientService.addAdamClientEmails(clientCopperID, { email: trimmedEmail });
    setEmails((prevEmails) => [
      ...prevEmails,
      { email: newEmail.email, id: newEmail.id }
    ]); // Add the new email to the list
    setNewEmail(""); // Clear the input field
  } catch (error) {
    console.error("Error adding email:", error.message);
  }
};

  const handleDelete = async (emailToDelete) => {
    try {
      await clientService.deleteAdamClientEmail(clientCopperID, emailToDelete);
      const updatedEmails = emails.filter(emailObject => emailObject.email !== emailToDelete);
      setEmails(updatedEmails); // Update the list after deletion

      alert('Email deleted successfully'); // Show success message
    } catch (error) {
      console.error("Error deleting email:", error.message);
      alert(`Failed to delete email: ${error.message}`); // Show detailed error message
    }
  };

  const handleCheckboxChange = (emailId, notificationType) => async (event) => {
    const isChecked = event.target.checked;

    setCheckedState((prevState) => ({
      ...prevState,
      [emailId]: {
        ...prevState[emailId],
        [notificationType]: isChecked,
      },
    }));

    if (isChecked) {
      try {
        await clientService.addNotificationTypesToEmail(emailId, notificationType); // Sending as a string
        console.log('Notification type updated successfully for', emailId);
      } catch (error) {
        console.error('Error updating notification type:', error);
      }
    } else {
      try {
        await clientService.removeNotificationTypesFromEmail(emailId, [notificationType]); // Remove the notification type if unchecked
        console.log('Notification type removed successfully for', emailId);
      } catch (error) {
        console.error('Error removing notification type:', error);
      }
    }
  };

  useEffect(() => {
    // Run the handleShow function when the component mounts
    handleShow();
  }, []); // Empty dependency array ensures that it only runs once when the component mounts



  return (
    <>
      {/* <Button onClick={handleShow}>Edit Email</Button> */}
      <Dialog
        open={show}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "800px",
            height: "500px",
            maxWidth: "100%",
            maxHeight: "100%",
          },
        }}
      >
        <DialogContent>
          <TableContainer>
            <Table sx={{ minWidth: "400px" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "solid 1px black" }}
                  >
                    Emails
                    
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "20%", borderRight: "solid black 1px" }}
                  >
                    Live Trade Confirmation
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "20%",
                      borderRight: "solid black 1px",
                      overflow: "hidden",
                    }}
                  >
                   Daily Trade Confirmation
                  </TableCell>
                  <TableCell align="center" sx={{
                      width: "20%",
                      overflow: "hidden",
                    }}>Monthly Trade Report</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ boxShadow: "none"}}>
                {emails.map((emailObject) => (
                  <TableRow key={emailObject.id}>
                    <TableCell
          component="th"
          scope="row"
          sx={{ borderRight: "solid 1px black" }}
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{
            gap: '0.5em'
          }}>
            {emailObject.email}
            <button className="delete-button" onClick={() => handleDelete(emailObject.email)}>Delete</button>

          </Box>
        </TableCell>
                    
                    <TableCell
                      align="center"
                      sx={{ borderRight: "solid 1px black" }}
                    >
                      <Checkbox
                      sx={{
                        color: '#EBB000', // Color when not checked
                        '&.Mui-checked': {
                          color: '#EBB000',
                          backgroundColor: 'white' // Color when checked
                        },
                      }}
                        checked={
                          checkedState[emailObject.id]?.tradeConfirmation ||
                          false
                        }
                        onChange={handleCheckboxChange(
                          emailObject.id,
                          "tradeConfirmation"
                        )}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderRight: "solid 1px black" }}
                    >
                      <Checkbox
                      sx={{
                        color: '#EBB000', // Color when not checked
                        '&.Mui-checked': {
                          color: '#EBB000',
                          backgroundColor: 'white' // Color when checked
                        },
                      }}
                        checked={checkedState[emailObject.id]?.talos || false}
                        onChange={handleCheckboxChange(emailObject.id, "talos")}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                      sx={{
                        color: '#EBB000', // Color when not checked
                        '&.Mui-checked': {
                          color: '#EBB000',
                          backgroundColor: 'white' // Color when checked
                        },
                      }}
                        checked={
                          checkedState[emailObject.id]?.monthlyTradeReport ||
                          false
                        }
                        onChange={handleCheckboxChange(
                          emailObject.id,
                          "monthlyTradeReport"
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1em",
            }}
          >
          
            <TextField
              value={addEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              variant="outlined"
              size="small"
              helperText="Please enter email here"
              sx={{ borderRadius: 0, height: "40px", marginRight: "1em" }}
            />
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#EBB000",
                color: "black",
                borderRadius: 0,
                border: "1px",
                height: "40px",
                "&:hover": {
                  backgroundColor: "#d1a000",
                },
              }}
            >
              Add Email
            </Button>
          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditEmailModal;
