/**
 * Balances Service
 */
export class BalancesService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async getFireblocks(){
        const response = await fetch(
            this.API_URL + '/fireblocksAggregatedBalances',
            {
                headers: {
                    // 'Content-Type': 'application/json'
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async geLPBalances(provider){
        const response = await fetch(
            this.API_URL + '/lpbalances/'+provider,
            {
                headers: {
                    // 'Content-Type': 'application/json'
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async getBalances(){
        const response = await fetch(
            this.API_URL + '/allbalances',
            {
                headers: {
                    // 'Content-Type': 'application/json'
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async getCustomerBalances(){
        const response = await fetch(
            this.API_URL + '/clientbalances',
            {
                headers: {
                    // 'Content-Type': 'application/json'
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    // async setBcbClient(bcbID, clientCopperID) {
    //     const response = await fetch(
    //         this.API_URL + '/bcb/setclient', {
    //         method: 'PUT',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${this.accessToken}`
    //         },
    //         body: JSON.stringify({
    //             bcbID,
    //             clientCopperID
    //         })
    //     });
    //     return response.json();
    // }

}