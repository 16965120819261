import React, { useState, useEffect } from 'react';
import RawOpenpayData from './RawOpenpayData';
import { OpenpaydService } from '../../../api/OpenpaydService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import { ClientService } from '../../../api/ClientService';
import { CircularProgress } from '@mui/material';
import usePagination from '../../../hooks/usePagination';
import useAdamClients from '../../../hooks/useAdamClients';

function Openpayd({ accessToken }) {

    const [rawData, setRawData] = useState([])
    const [update, setUpdate] = useState(0);

    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)

    const openpaydService = new OpenpaydService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    const currencyPerTab = [
        undefined,
        'CAD',
        'USD',
        'GBP',
        'EUR'
    ]

    useEffect(() => {
        setLoading(true)

        openpaydService.getAllOpenpayd(paginationProps.pageSize, paginationProps.pageIndex, currencyPerTab[activeTab]).then(rawCF => {
            setRawData(rawCF.data.sort((a, b) => Date.parse(b.updated_date) - Date.parse(a.updated_date)))
            paginationProps.setTotalItemCount(rawCF.totalItems)

            setLoading(false)
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [paginationProps.pageIndex, paginationProps.pageSize, activeTab])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        paginationProps.setPageIndex(0)
    }

    const updateRawDataEntry = (transactionID, newData) => {
        const index = rawData.findIndex(transaction => transaction.id === transactionID)

        setRawData(oldRawData => [
            ...oldRawData.slice(0, index),
            newData,
            ...oldRawData.slice(index + 1)
        ])
    }
    return (
        // <div>
        //     <RawOpenpayData
        //         rawData={rawData}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label="ALL" />
                <Tab label="CAD" />
                <Tab label="USD" />
                <Tab label="GBP" />
                <Tab label="EUR" />
            </Tabs>
            {loading? 
            <CircularProgress />
            :<div style={{width: '100%'}}>
                <RawOpenpayData 
                    rawData={rawData} loading={loading || clientsLoading}
                    clients={clients}
                    update={update}
                    setUpdate={setUpdate}
                    setOpenpaydClient={(openpaydTxId, clientCopperID) => openpaydService.setOpenpaydClient(openpaydTxId, clientCopperID)}
                    updateRawDataEntry={updateRawDataEntry}
                    paginationProps={paginationProps}
                />
            </div>}
        </Box>
    );
}

export default Openpayd;