export class FireblocksService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async getAllFireblocks(pageSize, pageNum) {
        const response = await fetch(
            this.API_URL + `/fireblocks?size=${pageSize}&page=${pageNum}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            }
        );
        return response.json();
    }

    async setFireblocksClient(fireblocksID, clientCopperID) {
        const response = await fetch(
            this.API_URL + '/fireblocks/setclient',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.accessToken}`
                },
                body: JSON.stringify({
                    fireblocksID,
                    clientCopperID
                })
            }
        );
        return response.json();
    }

    async downloadFireblocksDirectionless(sheetType) {
        const contentTypes = {
            csv: "text/csv",
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        };

        const response = await fetch(
            this.API_URL + `/fireblocks_export/directionless?fileType=${sheetType}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': contentTypes[sheetType] ?? 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`
                }
            }
        )

        return await response.blob()
    }

}