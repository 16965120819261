import React, {useEffect, useState} from 'react';
import usePagination from '../../../../hooks/usePagination';
import { LPsService } from '../../../../api/LPsService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../../components/transactionInfoModal';
import SimpleTablePagination from '../../../../components/SimpleTablePagination';
function Aquanow({accessToken}) {
    const lpsService = new LPsService(accessToken);
    const [loading, setLoading] = useState(false);
    const [rawData, setRawData] = useState([]);

    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const paginationProps = usePagination();

    useEffect(() => {
        setLoading(true)

        lpsService.getAllAquanow(paginationProps.pageSize, paginationProps.pageIndex).then(rawLP => {
            let sortedData = rawLP.data.sort((a, b) => new Date(b.trade_time)- new Date(a.trade_time))

            setRawData(sortedData)
            paginationProps.setTotalItemCount(rawLP.totalItems)

            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [paginationProps.pageSize, paginationProps.pageIndex]);

    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }

    const RawDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{moment(rawDataRow.trade_time).utcOffset(-4 * 60).format('YYYY-MM-DD hh:mm A')}</TableCell>
                <TableCell align='left'>{rawDataRow.id}</TableCell>
                <TableCell align='left'>{rawDataRow.side}</TableCell>
                <TableCell align='left'>{rawDataRow.currency}</TableCell>
                <TableCell align='left'>{rawDataRow.quantity}</TableCell>
                <TableCell align='left'>{rawDataRow.price}</TableCell>
                <TableCell align='left'>{rawDataRow.amount} {rawDataRow.amount_currency}</TableCell>
                <TableCell align='left'>{rawDataRow.symbol}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
                {/* <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenOrderDetailDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell> */}
            </TableRow>
        )
    }
    const table = rawData.map((rawDataRow,index) => RawDataRow(rawDataRow,index))

    if (rawData.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Id</TableCell> */}
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Order ID</TableCell> 
                                <TableCell align="left">Side</TableCell>
                                <TableCell align="left">Currency</TableCell>
                                <TableCell align="left">Quantity</TableCell>
                                <TableCell align="left">Price</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Asset Pair</TableCell>    
                                <TableCell align="left">Order detail</TableCell>
                                {/* <TableCell align="left">Customer Quote</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {table}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Aquanow Order Details"}
            />
            {/* <TransactionInfoModal 
                openDialog={openOrderDetail} 
                closeDialog={handleCloseOrderDetailDialog}
                data={txData}
                title={"Talos Customer Quote Details"}
            /> */}
        </div>
    );
}

export default Aquanow;