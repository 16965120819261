import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

import moment from 'moment'

export default function ClientReportBalanceAdjustmentTable({ balanceOffsets }) {
    return (
        <TableContainer component={Paper} sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
            <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                    <TableRow>
                        <TableCell>Asset</TableCell>
                        <TableCell>Adjustment Amount</TableCell>
                        <TableCell>Note</TableCell>
                        <TableCell>Adjustment Date and Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        balanceOffsets.map(balanceOffset => (
                            <TableRow hover key={balanceOffset.id}>
                                <TableCell>{balanceOffset.asset}</TableCell>
                                <TableCell>{balanceOffset.offset}</TableCell>
                                <TableCell>{balanceOffset.note}</TableCell>
                                <TableCell>{moment(balanceOffset.createdAt).format('LLL')}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}