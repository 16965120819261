import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SimpleTablePagination from '../../../components/SimpleTablePagination'
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import { Autocomplete, TextField } from '@mui/material';
import ClientAutocompleteSelector from '../../../components/clientAutocompleteSelector';
import { rates } from '../TradeTicketGenerator/rates';
function RawFireblocksData({rawFireblocks, clients, updateRawFireblocksEntry, setFireblocksClient, paginationProps}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }

    /**
     * Retrieves the primary name of the Fireblocks client from the given raw data.
     * @param {object} fireblocksRawData - The raw data object containing Fireblocks client information.
     * @returns {string | null} The primary name of the Fireblocks client, or null if not found.
     */
    const getFBClientName = fireblocksRawData => {
        const [ fbClient ] = fireblocksRawData.adamClients
        return fbClient ? fbClient.clientPrimaryName : null
    }

    /**
     * Updates the client side data with the provided option and fireblocks raw data.
     * @param {any} option - The option to update the client side data with.
     * @param {object} fireblocksRawData - The raw fireblocks data.
     * @returns None
     */
    const updateClientSide = (option, fireblocksRawData) => {
        const newData = { ...fireblocksRawData }

        // Get client associated with selected option (should be list of 1 element)
        const fbClient = clients.filter(client => client.clientCopperId === option?.id)

        newData.adamClients = fbClient
        updateRawFireblocksEntry(newData.id, newData)
    }

    /**
     * Handles the change of a client option and updates the Fireblocks client.
     * @param {any} option - The selected client option.
     * @param {any} fireblocksRawData - The raw data of the Fireblocks client.
     * @returns None
     */
    const handleClientChange = async (option, fireblocksRawData) => {
        await setFireblocksClient(fireblocksRawData.id, option?.id)
        .then(res => {
            if(res) {
                updateClientSide(option, fireblocksRawData)
            }
            else {
                console.error('Something went wrong while updating Fireblocks transaction')
            }
        })
    }

    const RawFireblocksDataRow = (rawFireblocksDataRow, index) => {
        // console.log('rawFireblocksDataRow', rawFireblocksDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                {/* <TableCell>{rawFireblocksDataRow.fireblocksdata.id}</TableCell> */}
                <TableCell align='left' sx={{width: '10%'}}>{rawFireblocksDataRow.fireblocksdata.note}</TableCell>
                <TableCell align='left'>{rawFireblocksDataRow.fireblocksdata.source.name}</TableCell>
                <TableCell align='left'>{rawFireblocksDataRow.fireblocksdata.destination.name}</TableCell>
                <TableCell align='left'>{rawFireblocksDataRow.fireblocksdata.assetId}</TableCell>
                <TableCell align='left'>{Number(rawFireblocksDataRow.fireblocksdata.amount).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})}</TableCell>
                <TableCell align='left'>{moment(rawFireblocksDataRow.fireblocksdata.createdAt).format('L')}</TableCell>
                <TableCell align='left' style={{whiteSpace: 'nowrap'}}>
                    {rawFireblocksDataRow.adamFireblocksUser? rawFireblocksDataRow.adamFireblocksUser.firstName + " " + rawFireblocksDataRow.adamFireblocksUser.lastName : ''}
                </TableCell>
                <TableCell align='left'>
                    <ClientAutocompleteSelector
                        clients={clients}
                        onChange={(e, v) => handleClientChange(v, rawFireblocksDataRow)}
                        value={getFBClientName(rawFireblocksDataRow)}
                        onClear={() => handleClientChange(null, rawFireblocksDataRow)}
                    />
                </TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawFireblocksDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const fireblocksTable = rawFireblocks.map((rawFireblocksDataRow,index) => RawFireblocksDataRow(rawFireblocksDataRow,index))
    if (rawFireblocks.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Id</TableCell> */}
                                <TableCell align="left" sx={{width: '10%'}}>Note</TableCell>
                                <TableCell align="left">Source</TableCell>
                                <TableCell align="left">Destination</TableCell>
                                <TableCell align="left">Asset</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Created By</TableCell>
                                <TableCell align="left">Client</TableCell>
                                <TableCell align="left">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fireblocksTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Fireblocks Transaction Details"}
            />
        </div>
    );
}

export default RawFireblocksData;