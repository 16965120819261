import TablePagination from '@mui/material/TablePagination'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import { useState, useEffect, useCallback } from 'react'

export default function SimpleTablePagination({ paginationProps }) {
    const [ pageNumInput, setPageNumInput ] = useState(0)

    const getMaxPageNum = useCallback(() => {
        return Math.ceil(paginationProps.totalItemCount / paginationProps.pageSize)
    }, [paginationProps.totalItemCount, paginationProps.pageSize])

    useEffect(() => {
        setPageNumInput(paginationProps.pageIndex + 1)
    }, [paginationProps.pageIndex, paginationProps.totalItemCount, paginationProps.pageSize])

    const handlePageInputChange = e => {
        const newVal = e.target.value
        const newPage = parseInt(newVal)

        if(newVal === '') {
            setPageNumInput(0)
            return
        }
        else if(!newPage) {
            return
        }

        const maxPage = getMaxPageNum()

        if(newPage > maxPage) {
            return
        }

        setPageNumInput(newPage)
    }

    const navigateToInputPage = () => {
        paginationProps.setPageIndex(pageNumInput - 1)
    }

    return (
        <Stack direction='row'>
            <div style={{ display: 'flex', padding: 10, alignItems: 'center', gap: 10 }} >
                <b>Page: </b>
                <TextField variant='standard' style={{ width: 50 }} value={pageNumInput || ''} onChange={handlePageInputChange} />
                <b>of {getMaxPageNum()}</b>
                <Button onClick={() => navigateToInputPage()} disabled={paginationProps.pageIndex === pageNumInput - 1 || !pageNumInput}>Navigate</Button>
            </div>
            <TablePagination
                rowsPerPage={paginationProps.pageSize}
                count={paginationProps.totalItemCount}
                page={paginationProps.pageIndex}
                onPageChange={(_, page) => paginationProps.setPageIndex(page)}
                onRowsPerPageChange={evt => paginationProps.setPageSize(evt.target.value)}
                rowsPerPageOptions={[10, 50, 200, 1000]} // You can adjust this
            />
        </Stack>
    )
}