import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function LPTXDialog({open,handleClose, Transition, transactions }) {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} color='transparent'>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Transactions
                </Typography>
            </Toolbar>
            </AppBar>
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Direction</TableCell>
                            <TableCell align="left">Contract</TableCell>
                            <TableCell align="left">Source</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Amount In USD</TableCell>
                            <TableCell align="left">Date</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {transactions && transactions.map((row) => (
                            <TableRow
                                key={row.contractNumber}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.direction}
                            </TableCell>
                            <TableCell align="left">{row.contractNumber}</TableCell>
                            <TableCell align="left">{row.source}</TableCell>
                            <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amount : row.in.amount}</TableCell>
                            <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amountUSD : row.in.amountUSD}</TableCell>
                            <TableCell align="left">{row.date}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Dialog>
    );
}

export default LPTXDialog;