import React, { useEffect, useState } from 'react';
import { BalancesService } from '../../../api/BalancesService';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinearProgress from '@mui/material/LinearProgress';
import { LoadingButton } from '@mui/lab';
import * as XLSX from 'xlsx';
function Row({ row }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.assetId}
                </TableCell>
                <TableCell align="left">{row.total.toLocaleString()}</TableCell>
                <TableCell align="left">{row.available.toLocaleString()}</TableCell>
                <TableCell align="left">{row.lockedAmount.toLocaleString()}</TableCell>
                <TableCell align="left">{row.pending.toLocaleString()}</TableCell>
                <TableCell align="left">{row.frozen.toLocaleString()}</TableCell>
                <TableCell align="left">{row.staked.toLocaleString()}</TableCell>
                <TableCell align="left">{row.createdAt}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Asset Balances
                        </Typography>
                        <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell>Vault ID</TableCell>
                                <TableCell>Vault Name</TableCell>
                                <TableCell align="right">Available</TableCell>
                                <TableCell align="right">Pending</TableCell>
                                <TableCell align="right">Frozen</TableCell>
                                <TableCell align="right">Staked</TableCell>
                                <TableCell align="right">Locked Amount</TableCell>
                                <TableCell align="right">Updated At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.vaults.map((assetRow) => (
                            <TableRow key={assetRow.id}>
                                <TableCell component="th" scope="row">
                                    {assetRow.vaultId}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {assetRow.vaultName}
                                </TableCell>
                                <TableCell>{assetRow.total}</TableCell>
                                <TableCell align="right">{assetRow.available}</TableCell>
                                <TableCell align="right">{assetRow.pending}</TableCell>
                                <TableCell align="right">{assetRow.frozen}</TableCell>
                                <TableCell align="right">{assetRow.staked}</TableCell>
                                <TableCell align="right">{assetRow.lockedAmount}</TableCell>
                                <TableCell align="right">{assetRow.updatedAt}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function FireblocksBal({token}) {
    let balanceService = new BalancesService(token)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false)
    const handleDownloadExcel = async() => {
        setDownloading(true)
        let rawComp = data
        console.log(rawComp);
        
        // console.log(rawComp);
        // rawComp = rawComp.data
        let header = ["Vault ID", "Vault Name", "Total", "Balance", "Available", "Pending", "Frozen", "Staked", "Locked Amount"];
        const wb = XLSX.utils.book_new();
        
        rawComp.map((row, index) => {
            let assetInfo = ['Asset:', row.assetId, '', 'Total:', parseFloat(row.total), '', 'Available:', parseFloat(row.available), '', 'Locked:', parseFloat(row.lockedAmount), '', 'Pending', parseFloat(row.pending), '', 'Frozen:', parseFloat(row.frozen), '', 'Staked:', parseFloat(row.staked)]
            let excelData = [assetInfo, header]
            row.vaults.map(vault => {
                let objCon = {
                    "Vault ID": vault.vaultId,
                    "Vault Name": vault.vaultName,
                    "Total": parseFloat(vault.total), 
                    "Balance": parseFloat(vault.balance), 
                    "Available": parseFloat(vault.available),
                    "Pending": parseFloat(vault.pending),
                    "Frozen": parseFloat(vault.frozen),
                    "Staked": parseFloat(vault.staked), 
                    "Locked Amount": parseFloat(vault.lockedAmount), 
                }
                excelData.push(Object.values(objCon))
                return objCon
            })
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            XLSX.utils.book_append_sheet(wb, ws, row.assetId);
        })
        // console.log(excelData)
        // const ws = XLSX.utils.aoa_to_sheet(excelData);
        // const wb = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'all-balance-overview');
        
        XLSX.writeFile(wb, "fireblocks-balance-sdm-"+ new Date().toISOString() + ".xlsx");
        setDownloading(false)
    }
    useEffect(() => {
        getFBBalances()
    },[])
    const getFBBalances = async() => {
        setLoading(true)
        let res = await balanceService.getFireblocks()
        console.log(res)
        setData(res)
        setLoading(false)
    }
    return (
        <div>
            <TableContainer component={Paper}>
                {loading ? <LinearProgress color="success"/> : null}
                <div style={{margin: 10}}>
                    <LoadingButton loading={downloading} variant="contained" disabled={!data} onClick={handleDownloadExcel}>
                        download Balance
                    </LoadingButton>
                </div>
                <Table aria-label="collapsible table">
                    <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Asset</TableCell>
                        <TableCell align="left">Total</TableCell>
                        <TableCell align="left">Available</TableCell>
                        <TableCell align="left">Locked</TableCell>
                        <TableCell align="left">Pending</TableCell>
                        <TableCell align="left">Frozen</TableCell>
                        <TableCell align="left">Staked</TableCell>
                        <TableCell align="left">Updated At</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((row) => (
                        <Row key={row.assetId} row={row} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default FireblocksBal;