import axios from 'axios'

const SDM_HUB_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const MANAGEMENT_CLIENT_ID = process.env.REACT_APP_MANAGE_CLIENT_ID;
const API_URL = process.env.REACT_APP_API_URL

export const instance = axios.create({
  baseURL: API_URL 
})


// // Dependency: Node.js crypto module
// // https://nodejs.org/api/crypto.html#crypto_crypto
// function base64URLEncode(str) {
//   return str.toString('base64')
//       .replace(/\+/g, '-')
//       .replace(/\//g, '_')
//       .replace(/=/g, '');
// }
// var verifier = base64URLEncode(crypto.randomBytes(32));

// // Dependency: Node.js crypto module
// // https://nodejs.org/api/crypto.html#crypto_crypto
// function sha256(buffer) {
//   return crypto.createHash('sha256').update(buffer).digest();
// }
// var challenge = base64URLEncode(sha256(verifier));

export const getUserRoles = async (userId) => {
  try {
    // console.log(API_URL)
    const res = await instance.post('/userRoles', {
      "userId": userId,
      "clientId": MANAGEMENT_CLIENT_ID ,
    })
    return res.data
  } catch (err) {
    return false
  }
}

export const getUserApiKey = async (accessToken, email) => {
  try {
    const res = await instance.post('/userApiKey', {
      "email": email,
    },{headers: {authorization: `Bearer ${accessToken}`}})

    return res.data
  } catch (err) {
    return false
  }
}

/**
 * isCustomerUser() checks if the current user is a Talos customer user
 * @param currentUserEmail email address of the current user
 * @returns boolean 
 */
export const isCustomerUser = async (currentUserEmail) => {
  try {
    const res = await instance.post('/customerUsers', {
      // Sending the current user's email in the request body
      currentUserEmail: currentUserEmail
    });
    // endpoint returns true or false
    return res.data;
  } catch (err) {
    console.log("Error getting customer users", err);
  }
}

export const getNews = async () => {
  try {
    const res = await instance.get('/newsUpdate')
    return res.data
  } catch (err) {
    console.log("error getting news", err)
  }
}
