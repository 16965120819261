import React from 'react';

function TradeReport(props) {
    return (
        <div style={{height: '100vh'}}>
            
            <iframe src="https://grafana202309.securedigitalmarkets.com/d/d0040643-effd-4712-bb02-b8724981f623/trade-report?orgId=1" width="100%" height="100%" frameborder="0" scrolling="auto" allowfullscreen></iframe>
        </div>
    );
}

export default TradeReport;