import React, { useState, useEffect } from "react";
import usePagination from "../../../hooks/usePagination";
import { ClientService } from "../../../api/ClientService";
import DeltecInfo from "./DeltecInfo";
import useAdamClients from "../../../hooks/useAdamClients";

const Deltec = ({ accessToken }) => {
  const [loading, setLoading] = useState(true);
  const [adamDeltecData, setAdamDeltecData] = useState([]);
  const [updateNames, setUpdateNames] = useState(0);
  const [shouldFetchData, setShouldFetchData] = useState(true);

  const clientService = new ClientService(accessToken);
  const paginationProps = usePagination();
  const { clients, clientsLoading } = useAdamClients(clientService);

  const updateAdamDeltecData = (updatedItem) => {
    setAdamDeltecData((currentData) => {
      return currentData.map((item) =>
        item.ledger_id === updatedItem.ledger_id ? { ...item, ...updatedItem } : item
      );
    });
    setShouldFetchData(true);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await clientService.getAdamDeltec(paginationProps.pageSize, paginationProps.pageIndex);

      console.log(paginationProps.pageIndex, paginationProps.pageSize)
      paginationProps.setTotalItemCount(data.totalItems);
      setAdamDeltecData(data.data);
      setUpdateNames(0);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      console.log("Fetching data...");
      setLoading(true);

      try {
        const data = await clientService.getAdamDeltec(
          paginationProps.pageSize,
          paginationProps.pageIndex
        );

        paginationProps.setTotalItemCount(data.totalItems);
        setAdamDeltecData(data.data);
        setUpdateNames(0);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setLoading(false);
      }
    };

    fetchDataAndSetState();
  }, [paginationProps.pageIndex, paginationProps.pageSize]);

  useEffect(() => {
    console.log("Fetching data...");

    setLoading(true);

    if (shouldFetchData) {
      fetchData();
      setShouldFetchData(false);
    }
  }, [paginationProps.pageIndex, paginationProps.pageSize, shouldFetchData]);

  useEffect(() => {
    if (updateNames === 1) {
      setShouldFetchData(true);
    }
  }, [updateNames,]);

  return (
    <div>
      <DeltecInfo
        adamDeltecData={adamDeltecData}
        paginationProps={paginationProps}
        setDeltecClient={(ledgerId, clientCopperID) =>
          clientService.setDeltecClient(ledgerId, clientCopperID)
        }
        clients={clients}
        loading={loading || clientsLoading}
        updateAdamDeltecData={updateAdamDeltecData}
      />
    </div>
  );
};

export default Deltec;