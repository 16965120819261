import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { LoadingButton } from '@mui/lab';
import { BalancesService } from '../../../api/BalancesService';
import * as XLSX from 'xlsx';
function AllBalances({token, customerBalance, customerLoading}) {
    console.log(customerLoading);
    const [downloading, setDownloading] = useState(false);
    let balanceService = new BalancesService(token)
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getBalances()
    },[])
    const getBalances = async() => {
        setLoading(true)
        console.log(balanceService);
        let res = await balanceService.getBalances()
        console.log(res)
        setLoading(false)
        setData(res)
    }

    const getTotalBalance = asset => {
        let total = 0

        total += data.fireblocksBal[asset]?.available || 0
        total += data.lpBalance[asset]?.balance || 0
        total -= customerBalance[asset] || 0

        return total
    }

    // Get merged and deduplicated list of all assets
    const allAssets = data && customerBalance ? [ ...new Set([ ...data.assets, ...Object.keys(customerBalance) ]) ] : []

    const handleDownloadExcel = async() => {
        setDownloading(true)
        let rawComp = data.assets
        // console.log(rawComp);
        // rawComp = rawComp.data
        // let wacInfo = ['WAC:', parseFloat(wacData.weightedAvgCost).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}), '', 'Inventory Available:', parseFloat(wacData.assetQuantity).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}), '', 'USD Amount:', parseFloat(wacData.totalUsdVal).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})]
        let header = ["Assets", "Total Units (FB)", "Total Units (LP)", "Total Units (Customer)", "SDM Inc. Inventory"];
        let excelData = [header]
        rawComp.map((row, index) => {
            let objCon = {
                "Assets": row,
                "Total Units (FB)": data.fireblocksBal[row] ? parseFloat(data.fireblocksBal[row].available.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})): 0,
                "Total Units (LP)": data.lpBalance[row] ? parseFloat(data.lpBalance[row].balance.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})): 0,
                "Total Units (Customer)": customerBalance[row] ? parseFloat((-1 * customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5})) : 0,
                "SDM Inc. Inventory": getTotalBalance(row).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5})
            }
            excelData.push(Object.values(objCon))
            return objCon
        })
        console.log(excelData)
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'all-balance-overview');
        
        XLSX.writeFile(wb, "all-balance-overview-sdm-"+ new Date().toISOString() + ".xlsx");
        setDownloading(false)
    }
    return (
        <div>
            <div style={{margin: 10}}>
                <LoadingButton loading={downloading} variant="contained" disabled={!data || !customerBalance} onClick={handleDownloadExcel}>
                    download Balance
                </LoadingButton>
            </div>
            <TableContainer component={Paper}>
                {loading ||  customerLoading ? <LinearProgress color="success"/> : null}
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Assets</TableCell>
                        <TableCell align="left">Total Units (FB)</TableCell>
                        <TableCell align="left">Total Units (LP)</TableCell>
                        <TableCell align="left">Total Units (Customer)</TableCell>
                        <TableCell align="left">SDM Inc. Inventory</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data && customerBalance  && allAssets.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row}
                        </TableCell>
                        <TableCell align="left">{data.fireblocksBal[row] ? data.fireblocksBal[row].available.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}): 0}</TableCell>
                        <TableCell align="left">{data.lpBalance[row] ? data.lpBalance[row].balance.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}): 0}</TableCell>
                        <TableCell align="left">{customerBalance[row] ? (-1 * customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}) : 0}</TableCell>
                        {/* <TableCell align="left">
                            {data.fireblocksBal[row] ? 
                                    customerBalance[row] ? 
                                        data.lpBalance[row] ? (data.fireblocksBal[row].available +  data.lpBalance[row].balance + -1 * customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}) 
                                        : (data.fireblocksBal[row].available + -1 * customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}) 
                                        : data.lpBalance[row] ? (data.fireblocksBal[row].available +  data.lpBalance[row].balance).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5})
                                        : (data.fireblocksBal[row].available).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5})
                                    : customerBalance[row] ? (customerBalance[row]).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5}): 0}
                        </TableCell> */}
                        <TableCell align="left">
                            {getTotalBalance(row).toLocaleString({maximumFractionDigits: 5, minimumFractionDigits: 5})}
                        </TableCell>
                        {/* <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amount : row.in.amount}</TableCell>
                        <TableCell align="left">{row.direction == 'SELL' ? '-' + row.out.amountUSD : row.in.amountUSD}</TableCell>
                        <TableCell align="left">{row.date}</TableCell> */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default AllBalances;