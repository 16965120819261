import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import { Autocomplete, TextField } from '@mui/material';
import SimpleTablePagination from '../../../components/SimpleTablePagination';
import ClientAutocompleteSelector from '../../../components/clientAutocompleteSelector';

function RawFinclusiveData({rawData, loading, clients, update, setUpdate,setFinclusiveClient, updateRawDataEntry, paginationProps}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const clientOptions = clients.map(client => ({
        id: client.clientCopperId,
        label: client.clientPrimaryName
    }))
    const getFinclusiveClientName = finclusiveRawData => {

        const [ finclusiveClient ] = finclusiveRawData.adamClients
        return finclusiveClient ? finclusiveClient.clientPrimaryName : null
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }
    const updateClientSide = (option, RawData) => {
        const newData = { ...RawData }

        // Get client associated with selected option (should be list of 1 element)
        const finClient = clients.filter(client => client.clientCopperId === option?.id)

        newData.adamClients = finClient
        updateRawDataEntry(newData.id, newData)
    }
    const handleClientChange = async (option, RawData) => {
        await setFinclusiveClient(RawData.id, option?.id)
        .then(res => {
            if(res) {
                updateClientSide(option, RawData)
            }
            else {
                console.error('Something went wrong while updating Finclusive transaction')
            }
        })
    }
    const RawCFDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)
        // Prepare formatter for currency formatting; commas, dollar-signs..
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        let the_amount = rawDataRow.amount;  // initialize to provided value

        // factor into Amount the value of transaction_type; add a minus for Debit
        if (rawDataRow.transaction_type === 'Debit') // must be a debit
        {
            the_amount = '-' + the_amount; // prepend a minus
        }

        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{rawDataRow.transaction_method}</TableCell>
                <TableCell align='left'>{formatter.format(the_amount)}</TableCell>
                <TableCell align='left'>{rawDataRow.currency}</TableCell>
                <TableCell align='left'>{rawDataRow.description}</TableCell>
                <TableCell align='left'>{rawDataRow.status}</TableCell>
                <TableCell align='left'>{moment(rawDataRow.date).format('L')}</TableCell>
                <TableCell align='left'>{moment(rawDataRow.settlement_date).format('L')}</TableCell>
                <TableCell align='left'>
                    <ClientAutocompleteSelector
                        clients={clients}
                        value={getFinclusiveClientName(rawDataRow)}
                        onChange={(e, v) => handleClientChange(v, rawDataRow)}
                        onClear={() => handleClientChange(null, rawDataRow)}
                    />
                </TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const finclusiveTable = rawData.map((rawDataRow,index) => RawCFDataRow(rawDataRow,index))

    if (rawData.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Method</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Settle</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell align="left"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {finclusiveTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Finclusive Transaction Details"}
            />
        </div>
    );
}

export default RawFinclusiveData;