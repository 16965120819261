import { useState, useEffect } from 'react'

const useAdamClients = clientService => {
    const [clientsLoading, setClientsLoading] = useState(true)
    const [clients, setClients] = useState([])

    useEffect(() => {
        clientService.getAllClients().then(clients => {
            setClients(clients)
            setClientsLoading(false)
        })
        .catch(err => {
            console.error(err)
            setClientsLoading(false)
        })
    }, [])

    return {
        clients,
        clientsLoading
    }
}

export default useAdamClients