import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from "@auth0/auth0-react";
function PermissionError(props) {
    const { logout } = useAuth0();
    const logoutAuth0 = () => {
        console.log("log out")
        logout({ returnTo: window.location.origin })
    }
    return (
        <Card sx={{ width: '30%', borderRadius: '2px', backgroundColor: '#2A2A2A', alignSelf: 'center' }}>
            <CardContent sx={{display: 'flex', justifyContent: 'center', flexDirection:'column' }}>
                <ReportGmailerrorredIcon color='error' sx={{fontSize: 40, alignSelf: 'center'}}/>
                <span style={{color: '#ffff', textAlign: 'center'}}>You don't have access to SDM Hub</span>
                <Button variant="text" startIcon={<LogoutIcon />} sx={{color: 'white' }} onClick={() => logoutAuth0()}>
                    Sign Out
                </Button>
            </CardContent>
        </Card>
    );
}

export default PermissionError;