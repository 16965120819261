import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import SimpleTablePagination from '../../../components/SimpleTablePagination';

function RawTalosData({rawData, loading, paginationProps}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }
    const RawCFDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                {/* <TableCell>{rawDataRow.id}</TableCell> */}
                <TableCell align='left'>{moment(rawDataRow.transact_time).utcOffset(-4 * 60).format('YYYY-MM-DD hh:mm A')}</TableCell>
                <TableCell align='left'>{rawDataRow.data.OrderID}</TableCell>
                <TableCell align='left'>{rawDataRow.side}</TableCell>
                <TableCell align='left'>{rawDataRow.currency}</TableCell>
                <TableCell align='left'>{rawDataRow.quantity}</TableCell>
                <TableCell align='left'>{rawDataRow.price}</TableCell>
                <TableCell align='left'>{rawDataRow.amount} {rawDataRow.amount_currency}</TableCell>
                {/* <TableCell align='left'>{}</TableCell> */}
                <TableCell align='left'>{rawDataRow.market}</TableCell>
                <TableCell align='left'>{rawDataRow.user}</TableCell>
                <TableCell align='left'>{rawDataRow.sub_account}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const talosTable = rawData.map((rawDataRow,index) => RawCFDataRow(rawDataRow,index))

    if (rawData.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Id</TableCell> */}
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Order ID</TableCell>
                                <TableCell align="left">Side</TableCell>
                                <TableCell align="left">Currency</TableCell>
                                <TableCell align="left">Quantity</TableCell>
                                <TableCell align="left">Price</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                {/* <TableCell align="left">Amount Currency</TableCell> */}        
                                <TableCell align="left">Market</TableCell>   
                                <TableCell align="left">User</TableCell>  
                                <TableCell align="left">Sub Account</TableCell>    
                                
                                <TableCell align="left"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {talosTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Talos LP Transaction Details"}
            />
        </div>
    );
}

export default RawTalosData;