import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SimpleTablePagination from '../../../components/SimpleTablePagination'
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import ClientAutocompleteSelector from '../../../components/clientAutocompleteSelector';
import SearchIcon from '@mui/icons-material/Search';

function RawFortressData({rawData, loading, clients, update, setUpdate,setFortressClient, updateRawDataEntry, paginationProps}) {
    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();

    const [openTxSourceInfo, setOpenTxSourceInfo] = useState(false);
    const [txSourceData, setTxSourceData] = useState();
    
    const [openTxDestinationInfo, setOpenTxDestinationInfo] = useState(false);
    const [txDestinationData, setTxDestinationData] = useState();
    const handleOpenTxInfoDialog = (row, index) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }

    const handleOpenTxSourceInfoDialog = (row, index) => {
        setTxSourceData(row)
        setCurTxIndex(index)
        setOpenTxSourceInfo(true)
    }

    const handleOpenTxDestinationInfoDialog = (row, index) => {
        setTxDestinationData(row)
        setCurTxIndex(index)
        setOpenTxDestinationInfo(true)
    }

    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(-1)
    }

    const handleCloseTxSourceInfoDialog = () => {
        setOpenTxSourceInfo(false)
        setCurTxIndex(-1)
    }

    const handleCloseTxDestinationInfoDialog = () => {
        setOpenTxDestinationInfo(false)
        setCurTxIndex(-1)
    }
    const clientOptions = clients.map(client => ({
        id: client.clientCopperId,
        label: client.clientPrimaryName
    }))
    const getFortressClientName = fortressRawData => {
        const [ fortressClient ] = fortressRawData.adamClients
        return fortressClient ? fortressClient.clientPrimaryName : null
    }
    const updateClientSide = (option, RawData) => {
        const newData = { ...RawData }

        // Get client associated with selected option (should be list of 1 element)
        const fortressClient = clients.filter(client => client.clientCopperId === option?.id)

        newData.adamClients = fortressClient
        updateRawDataEntry(newData.id, newData)
    }
    const handleClientChange = async (option, RawData) => {
        await setFortressClient(RawData.id, option?.id)
        .then(res => {
            if(res) {
                updateClientSide(option, RawData)
            }
            else {
                console.error('Something went wrong while updating Fortress transaction')
            }
        })
    }
    const RawCFDataRow = (rawDataRow, index) => {
        // console.log('rawBcbDataRow', rawBcbDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left'>{moment(rawDataRow.data_created).format('L')}</TableCell>
                {/* <TableCell align='left'>{rawDataRow.type}</TableCell> */}
                <TableCell align='left'>{rawDataRow.type.includes('WithdrawalReturn') ? rawDataRow.amount : rawDataRow.type.includes('Withdrawal') ? '-' + rawDataRow.amount: rawDataRow.type.includes('Return') ? '-' + rawDataRow.amount:rawDataRow.amount}</TableCell>
                <TableCell align='left'>{rawDataRow.asset}</TableCell>
                <TableCell align='left'>{rawDataRow.type}</TableCell>
                <TableCell align='left'>{rawDataRow.memo}</TableCell>
                <TableCell align='left'>{rawDataRow.comment}</TableCell>
                <TableCell align='left'>{rawDataRow.description}</TableCell>
                <TableCell align='left'>{rawDataRow.status}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxSourceInfoDialog(rawDataRow.source, index)}
                    >
                        <SearchIcon color="action"/>
                    </IconButton>
                </TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxDestinationInfoDialog(rawDataRow.destination, index)}
                    >
                        <SearchIcon color="info"/>
                    </IconButton>
                </TableCell>
                <TableCell align='left'>
                    <ClientAutocompleteSelector
                        clients={clients}
                        onChange={(e, v) => handleClientChange(v, rawDataRow)}
                        value={getFortressClientName(rawDataRow)}
                        onClear={() => handleClientChange(null, rawDataRow)}
                    />
                </TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTxInfoDialog(rawDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const fortressTable = rawData.map((rawDataRow,index) => RawCFDataRow(rawDataRow,index))
    if (rawData.length === 0) return null
    else return (
        <div>
            <Paper>
                <TableContainer sx={{ maxHeight: 550, width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Date</TableCell>
                                {/* <TableCell>Type</TableCell> */}
                                <TableCell align="left">Amount</TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Memo</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Source</TableCell>
                                <TableCell>Destination</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell align="left"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fortressTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SimpleTablePagination paginationProps={paginationProps} />
            </Paper>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
                title={"Fortress Transaction Details"}
            />
            <TransactionInfoModal 
                openDialog={openTxSourceInfo} 
                closeDialog={handleCloseTxSourceInfoDialog}
                data={txSourceData}
                title={"Fortress Source Details"}
            />
            <TransactionInfoModal 
                openDialog={openTxDestinationInfo} 
                closeDialog={handleCloseTxDestinationInfoDialog}
                data={txDestinationData}
                title={"Fortress Destination Details"}
            />
        </div>
    );
}

export default RawFortressData;