// DateHelper.ts
import moment from 'moment';
// const moment = require('moment');
/**
 * Returns the current date in the format YYYYMMDD.
 */
export const getCurrentDateAsYYYYMMDD = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
};

/**
 * Get the current date in the format YYYYMMDD, adjusted for the current time zone.
 */
export const getCurrentTimeZoneDateAsYYYYMMDD = () => {
    const currentDate = moment().utcOffset(-4 * 60);

    const year = currentDate.format('YYYY');
    const month = currentDate.format('MM');
    const day = currentDate.format('DD');
    console.log(`${year}${month}${day}`);
    return `${year}${month}${day}`;
};
