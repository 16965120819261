export class WacService {

    constructor(accessToken) {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.accessToken = accessToken;
    }

    async getContractsByAsset(asset){        
        const response = await fetch(this.API_URL + '/getWACContractsByAssets?asset='+ asset, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`
            },
            body: null
        })
        return response.json();
    }

    async getWACByAsset(asset){        
        const response = await fetch(this.API_URL + '/getCurrentWAC?asset='+ asset, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`
            },
            body: null
        })
        return response.json();
    }
}