import React, { useEffect, useState } from 'react';
import { ClientService } from '../../../api/ClientService';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
function EditFeeModal({adamClient, feeEdited, update, setUpdate, accessToken, onClose}) {
    const [show, setShow] = useState(false);
    const [rateHikeChecked, setRateHikChecked] = useState(false);
    const handleShow = () => setShow(true);

    const clientService = new ClientService(accessToken);

    useEffect(() => {
        setRateHikChecked(adamClient.rateHikeWithDecliningVolume)
    },[adamClient])

    useEffect(() => {
        setShow(true);  // Open the modal when the component mounts
     }, [adamClient]);

    const handleEnableRateHikeChange = (event) => {
        setRateHikChecked(event.target.checked);
    };

    const { register, handleSubmit } = useForm();

    /**
     * Submits the edited data to update the client's fee and rate hike option.
     */
    const onEditSubmit = async (data) => {
        let counter = update + 1
        // console.log('EditFeeModal() data ', data)
        data.id = adamClient.clientCopperId;
        data.rateHikeWithDecliningVolume = rateHikeChecked
        console.log(data)
        try {
            /**
             * Sends a request to the client service to edit the client fee.
             */
            let res = await clientService.editClientFee(data)
            console.log(res);
            /**
             * Updates the rate hike option for a client.
             * @param {string} clientId - The ID of the client.
             * @param {boolean} rateHikeChecked - The new rate hike option for the client.
             */
            let rateRes = await clientService.updateClientRateHikeOption(adamClient.clientCopperId, rateHikeChecked)
            console.log(rateRes);
            setUpdate(counter)
            setShow(false);
        }
        catch(err) {
            //TODO: add error alert
            console.log(err)
        }
        
    };

    const handleClose = () => {
        // setShow(false);
        if (onClose) {
            onClose(); // call the callback function
        }
    };


    return (
        <div>
            {/* <Button variant="contained" color='warning' onClick={() => handleShow()}>Edit Fee</Button> */}
            <Dialog open={show} onClose={handleClose}>
                <DialogContent>
                <form onSubmit={handleSubmit(onEditSubmit)}>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label style={{fontWeight: 'bold'}}>Client's Primary Name: </label>
                                <p>{adamClient.clientPrimaryName}</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label style={{fontWeight: 'bold'}}>Client's Copper ID: </label>
                                <p>{adamClient.clientCopperId}</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px', position: 'relative', display: 'inline-block'}}>
                            <div className="form-group col-md-3">
                                <label htmlFor="fee" style={{fontWeight: 'bold'}}>Commission/Fee/Spread</label>
                                <input {...register("fee")} type="text" className="form-control" defaultValue={adamClient.adamClientFee && adamClient.adamClientFee.fee} name="fee" id="fee" />
                                <span style={{position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)'}}>%</span>
                            </div>
                        </div>
                        <div className="row" style={{margin: '5px', position: 'relative', display: 'inline-block'}}>
                            <div className="form-group col-md-3">
                            <FormGroup>
                                <FormControlLabel control={<Checkbox
                                    checked={rateHikeChecked}
                                    onChange={handleEnableRateHikeChange}
                                    // inputProps={{ 'aria-label': 'controlled' }}
                                />} label="Enable rate hike with declining trading volume" />
                            </FormGroup>
                                
                            </div>
                        </div>
                        <div className="btncenter">
                            <input type="submit" className="btn btn-danger" />
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EditFeeModal;
