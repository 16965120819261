import React, { useState, useEffect } from 'react';
import RawConnectFirstData from './RawConnectFirstData';
import { ConnectFirstService } from '../../../api/ConnectFirst';
import { ClientService } from '../../../api/ClientService';
import { Box, Tabs, Tab, Snackbar } from '@mui/material';
import CADOpex from './CADOpex';
import CADTrading from './CADTrading';
import USDData from './USDData';
import usePagination from '../../../hooks/usePagination'
import useAdamClients from '../../../hooks/useAdamClients'

function ConnectFirst({ accessToken }) {
    const [rawData, setRawData] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [balances, setBalances] = useState({})

    const connectFirstService = new ConnectFirstService(accessToken);
    const clientService = new ClientService(accessToken);

    const paginationProps = usePagination()
    const { clients, clientsLoading } = useAdamClients(clientService)

    /**
     * An array that stores the account IDs per tab.
     * The index of the array corresponds to the tab index.
     */
    const accountIDPerTab = [
        undefined, // for all accounts
        '899-1319-723112387318', // CAD Opex
        '899-369-723112119208', // CAD Trading
        '899-369-723112696429' // USD Trading
    ]

    useEffect(() => {
        getBalances()
    }, [])
    /**
     * Executes a side effect function that fetches data from the ConnectFirst service
     * based on the provided pagination properties and active tab.
     * @param {function} setLoading - A function to set the loading state.
     * @param {object} paginationProps - An object containing pagination properties (pageSize, pageIndex).
     * @param {string} activeTab - The currently active tab.
     */
    useEffect(() => {
        setLoading(true)

        connectFirstService.getAllConnectFirst(paginationProps.pageSize, paginationProps.pageIndex, accountIDPerTab[activeTab]).then(rawCF => {
            setRawData(rawCF.data.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)));
            paginationProps.setTotalItemCount(rawCF.totalItems)

            setLoading(false)
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [paginationProps.pageIndex, paginationProps.pageSize, activeTab])

    /**
     * Retrieves the balances from the ConnectFirst service asynchronously.
     */
    const getBalances = async() => {
        let res = await connectFirstService.getConnectFirstBalances()
        console.log(res);
        setBalances(res)
    }
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        paginationProps.setPageIndex(0)
    }

    /**
     * Updates the entry in the rawConnectFirst data array with the given transaction ID.
     * @param {string} transactionID - The ID of the transaction to update.
     * @param {object} newData - The new data to replace the old entry with.
     */
    const updateRawConnectFirstEntry = (transactionID, newData) => {

        // Use ID instead of index directly to handle filtered transaction lists
        const index = rawData.findIndex(transaction => transaction.id === transactionID)

        setRawData(oldRawCF => [
            ...oldRawCF.slice(0, index),
            newData,
            ...oldRawCF.slice(index + 1)
        ])
    }

    return (
        // <div>
        //     <RawConnectFirstData
        //         rawData={rawData}
        //         loading={loading}
        //     />
        // </div>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', marginTop: 5 }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                // className={classes.tabsContainer}
            >
                <Tab label={balances['ALL']? "ALL ($" + parseFloat(balances['ALL']).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3}) +")" : "ALL"}  />
                <Tab label={balances['899-1319-723112387318']? "CAD Opex ($" + parseFloat(balances['899-1319-723112387318']).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3}) +")" : "CAD Opex"} />
                <Tab label={balances['899-369-723112119208']? "CAD Trading ($" + parseFloat(balances['899-369-723112119208']).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3}) +")" : "CAD Trading"} />
                <Tab label={balances['899-369-723112696429'] ? "USD Trading ($" + parseFloat(balances['899-369-723112696429']).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3}) +")" : "USD Trading"} />
                {/* <Tab label="USD" /> */}
            </Tabs>
            <div style={{width: '100%'}}>
                <RawConnectFirstData rawData={rawData} loading={loading || clientsLoading} clients={clients} 
                    setConnectFirstClient={(connectFirstID, clientCopperID) => connectFirstService.setConnectFirstClient(connectFirstID, clientCopperID)}
                    updateRawConnectFirstEntry={updateRawConnectFirstEntry} paginationProps={paginationProps} />
            </div>
        </Box>
    );
}

export default ConnectFirst;