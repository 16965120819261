import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import TransactionInfoModal from '../../../components/transactionInfoModal';
import { Button, Tooltip, tooltipClasses, styled } from '@mui/material';
import { TradeTicketService } from '../../../api/TradeTicketService';
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
}));

function TradesTable({ accessToken, tradesData, loading, curUser, update, setUpdate, setErrorMsg, setIsError, setSuccessMsg, setIsSuccess }) {

    const [curTxIndex, setCurTxIndex] = useState(-1);
    const [openTradeDetailInfo, setOpenTradeDetailInfo] = useState(false);
    const [tradeData, setTradeData] = useState();
    const [openSelectedTooltip, setOpenSelectedTooltip] = useState('');

    /**
     * Creates a new instance of the TradeTicketService class with the provided access token.
     * @param {string} accessToken - The access token used for authentication.
     * @returns An instance of the TradeTicketService class.
     */
    const tradeTicketService = new TradeTicketService(accessToken);

    const handleOpenTradeDetailDialog = (row, index) => {
        setTradeData(row)
        setCurTxIndex(index)
        setOpenTradeDetailInfo(true)
    }

    const handleCloseTradeDetailDialog = () => {
        setCurTxIndex(-1)
        setOpenTradeDetailInfo(false)
    }
    const handleOpenSeletedDeleteToolTip = (id) => {
        setOpenSelectedTooltip(id)
    }
    const handleCloseSelectedToolTip = () => {
        setOpenSelectedTooltip('')
    }
    /**
     * Handles the confirmation of trade deletion.
     * @param {Object} trade - The trade object to be deleted.
     */
    const onConfirmTradeDeletion = async (trade) => {
        // console.log('curUser.nickname', curUser.nickname);
        // console.log('trade', trade);
        let count = update + 1
        let data = {
            username: curUser.nickname,
            trade: trade
        }
        let result = await tradeTicketService.deleteVoiceChatTradeTicket(trade)
        console.log('result', result);
        if(result == 1){
            handleCloseSelectedToolTip()
            setUpdate(count)
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Deleting trade successfully!")
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Deleting trade failed!")
        }
    }
    const RawTradeDataRow = (rawTradeDataRow, index) => {
        // console.log('rawPrimetrustDataRow', rawPrimetrustDataRow)
        return(
            <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                <TableCell align='left' sx={{ minWidth: 150 }}>{dayjs(rawTradeDataRow.date).format('lll')}</TableCell>
                <TableCell align='left'>{rawTradeDataRow.liquidity_provider}</TableCell>
                <TableCell align='left'>{rawTradeDataRow.direction}</TableCell>
                <TableCell align='left'>{parseFloat(rawTradeDataRow.amount_traded).toLocaleString(undefined, {maximumFractionDigits:4, minimumFractionDigits:4})}</TableCell>
                {/* <TableCell align='left'>{rawTradeDataRow.trade}</TableCell> */}
                <TableCell align='left'>{parseFloat(rawTradeDataRow.price).toLocaleString(undefined, {maximumFractionDigits:4, minimumFractionDigits:4})}</TableCell>
                <TableCell align='left'>{parseFloat(rawTradeDataRow.total_notional).toLocaleString(undefined, {maximumFractionDigits:4, minimumFractionDigits:4})}</TableCell>
                <TableCell align='left'>{rawTradeDataRow.trading_pair}</TableCell>
                <TableCell align='left'>{rawTradeDataRow.trader}</TableCell>
                <TableCell align='left'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenTradeDetailDialog(rawTradeDataRow, index)}
                    >
                        <InfoIcon color="success"/>
                    </IconButton>
                </TableCell>
                <HtmlTooltip
                    placement="left"
                    open={openSelectedTooltip == rawTradeDataRow.id}
                    arrow
                    title={
                        <React.Fragment>
                            <div style={{fontSize: '14px'}}>Are you sure you want to delete this trade?</div>
                            <Button sx={{m: 1}} color='info' size="small" variant="outlined" onClick={() => handleCloseSelectedToolTip()}>Cancel</Button>
                            <Button sx={{m: 1}} size="small" variant="contained" color='error' onClick={() => onConfirmTradeDeletion(rawTradeDataRow)}>OK</Button>
                        </React.Fragment>
                    }
                >
                    <TableCell align='right'>
                        <Button color='error' onClick={() => handleOpenSeletedDeleteToolTip(rawTradeDataRow.id)}>Delete</Button>
                    </TableCell>
                </HtmlTooltip>
            </TableRow>
        )
    }
    /**
     * Sorts the tradesData array in descending order based on the createdAt property of each object,
     * then maps each object to a RawTradeDataRow component with its corresponding index.
     * @param {Array} tradesData - An array of trade data objects.
     * @returns {Array} An array of RawTradeDataRow components.
     */
    const tradeTicketTable = tradesData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((rawTradeDataRow,index) => RawTradeDataRow(rawTradeDataRow,index))
    if (tradesData.length === 0) return null
    return (
        <div>
            <TableContainer component={Paper} sx={{ maxHeight: 400, width: '100%', margin: 'auto' }}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="left">Liquidity Provider</TableCell>
                            <TableCell align="left">Direction</TableCell>
                            <TableCell align="left">Amount Traded</TableCell>
                            {/* <TableCell align="left">Trade</TableCell> */}
                            <TableCell align="left">Price</TableCell>
                            <TableCell>Total Notional</TableCell>
                            <TableCell>Asset Pair</TableCell>
                            <TableCell>Trader</TableCell>
                            <TableCell align="left"/>
                            <TableCell align="left"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tradeTicketTable}
                    </TableBody>
                </Table>
            </TableContainer>
            <TransactionInfoModal 
                openDialog={openTradeDetailInfo} 
                closeDialog={handleCloseTradeDetailDialog}
                data={tradeData}
                title={"Trade detail"}
            />
        </div>
    );
}

export default TradesTable;