/**
 * Search function 
 */

export class searchAllClients {
  constructor(accessToken) {
    this.API_URL = process.env.REACT_APP_API_URL;
    this.accessToken = accessToken;
  }

  async getAllClientsData() {
    try {
      const response = await fetch(`${this.API_URL}/clients`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching all clients data:', error);
      return {
        success: false,
        error: 'Internal server error',
      };
    }
  }

}